import React, { useState } from 'react';

import { AnchorWithoutLink } from '@tuva-ui/components';

function IntroBlock() {
  const [showMore, setShowMore] = useState(false);

  return (
    <div className="py-3 px-4 bg-yellow-5 border">
      {!showMore && (
        <p className="mb-0">
          Tuva's searchable dataset library provides a wide selection of
          real-world datasets tailored for learning in science, math, and social
          studies classrooms. Tuva's datasets come from publicly available
          local, national...{' '}
          <AnchorWithoutLink
            onClick={e => {
              setShowMore(true);
            }}
            className="text-green text-underline mt-1"
          >
            Read More
          </AnchorWithoutLink>
        </p>
      )}

      {showMore && (
        <div>
          <p className="mb-0">
            Tuva's searchable dataset library provides a wide selection of
            real-world datasets tailored for learning in science, math, and
            social studies classrooms.
          </p>
          <p className="mb-0 mt-2">
            {' '}
            Tuva's datasets come from publicly available local, national, and
            international agencies, organizations, and published researchers.
            They are curated by Tuva staff to be accessible for students, and to
            inspire curiosity and fascination about a wide variety of dynamic
            phenomena, places, and processes. We update datasets regularly to
            reflect the dynamic nature of data in a changing world.
          </p>
          <p className="mb-0 mt-2">Use Tuva’s Dataset Library to:</p>
          <ol>
            <li>
              Search or filter for datasets by keyword, subject, grade band,
              dataset size, or by dataset properties.
            </li>
            <li>
              Review at a glance a short description of each dataset, its
              source, size, number of attributes, and other dataset properties.
            </li>
            <li>
              Open datasets quickly to read more about the data and review
              associated activities or a supplemental teaching guide.
            </li>
            <li>
              Create your own activity in a Tuva dataset, using Tuva’s Activity
              Builder
            </li>
            <li>
              Access your personal collection of “My Datasets” that you upload
              into your personal dataset library.
            </li>
          </ol>
          {/* <p className="mb-0 mt-2">
            <a
              target="new"
              href="https://docs.google.com/document/d/16mZBB_EYlxLZDePVn-4VxdhBTt6eMrLG4cdSnLbLbhI"
            >
              Click here
            </a>{' '}
            for more information about how we find, curate, and manage datasets
            for classroom use.
          </p> */}
          <p className="mb-0 mt-2">
            Datasets are the beating heart of Tuva - you will find them all in
            Tuva's Dataset Library.
          </p>
          <p className="mt-1">
            <AnchorWithoutLink
              onClick={e => {
                setShowMore(false);
              }}
              className="text-green text-underline mt-1"
            >
              Collapse
            </AnchorWithoutLink>
          </p>
        </div>
      )}
    </div>
  );
}

export default IntroBlock;
