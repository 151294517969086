import React from 'react';
import PropTypes from 'prop-types';

const Button = React.forwardRef(({ onClick, children, ...props }, ref) => {
  return (
    <button
      ref={ref}
      {...props}
      onClick={e => {
        // Prevent handling button double click
        if (e.detail === 1) {
          onClick(e);
        }
      }}
    >
      {children}
    </button>
  );
});

Button.propTypes = {
  onClick: PropTypes.func,
};

export default Button;
