import React from 'react';
import PropTypes from 'prop-types';

const Download = ({ url, appName }) => {
  return (
    <a
      className="p-1 no-underline"
      title="Download"
      target="_blank"
      href={url}
      rel="noreferrer"
      onClick={() => {
        window._gaq &&
          window._gaq.push([
            '_trackEvent',
            'Download CSV',
            'Click',
            { appName },
          ]);
      }}
    >
      <i className="ti ti-download"></i>
    </a>
  );
};

Download.propTypes = {
  url: PropTypes.string,
  appName: PropTypes.string,
};

export default Download;
