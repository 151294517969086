import React from 'react';
import PropTypes from 'prop-types';

const TableRow = ({ children, className, hideBottomDivider }) => {
  if (!hideBottomDivider) {
    className += ' pb-4 border-bottom';
  }
  return <div className={`clearfix pt-4 ${className}`}>{children}</div>;
};

TableRow.propTypes = {
  className: PropTypes.string,
  hideBottomDivider: PropTypes.bool,
};

TableRow.defaultProps = {
  className: '',
  hideBottomDivider: false,
};

export default TableRow;
