import axios from 'axios';

class ActivityService {
  getActivity(id) {
    return axios.get(`/api/activity/${id}`).then(response => {
      return response.data;
    });
  }

  addBookmark(objectType, objectId) {
    return axios
      .post(`/api/bookmark/`, {
        object_type: objectType,
        object_id: objectId,
      })
      .then(response => {
        return response.data;
      })
      .catch(err => {
        return;
      });
  }

  removeBookmark(bookmarkId) {
    return axios
      .delete(`/api/bookmark/${bookmarkId}/`)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        return;
      });
  }

  copyActivity(datasetSlug, activityId) {
    return axios
      .post(`/datasets/${datasetSlug}/copy/${activityId}/`)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        return;
      });
  }
}

const ActivityAPI = new ActivityService();

export default ActivityAPI;
