import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { BasicModal, Button, Loading } from '@tuva-ui/components/';
import DatasetNoteHistoryAPI from '../../DatasetNoteHistoryService';

const NotesHistoryModel = ({
  datasetId,
  showHistory,
  type,
  onClose,
  appTypeBaseUrl,
}) => {
  const [notes, setNotes] = useState(null);
  const [isNote, setIsNote] = useState(true);

  const fetchNotes = useCallback(() => {
    DatasetNoteHistoryAPI.getNotes(datasetId, type, appTypeBaseUrl)
      .then(function (data) {
        setNotes(data.staff_notes);
      })
      .catch(function (data) {
        console.log('Unable to get notes', data);
      });
  }, [datasetId, type]);

  const fetchHistory = useCallback(() => {
    DatasetNoteHistoryAPI.getHistory(datasetId, type, appTypeBaseUrl)
      .then(function (data) {
        setNotes(data.history);
      })
      .catch(function (data) {
        console.log('Unable to get history', data);
      });
  }, [datasetId, type]);

  useEffect(() => {
    if (showHistory) {
      setIsNote(false);
      fetchHistory();
    } else {
      fetchNotes();
    }
  }, [fetchNotes, fetchHistory, showHistory]);

  return (
    <BasicModal
      isOpen={true}
      style={{ minWidth: '40%', minHeight: '500px' }}
      className="popup popup-large"
      onClose={e => onClose()}
    >
      <BasicModal.Header headerText={'Dataset Notes / History'} />

      <div className="btn-grouped btngroup float-right my-2 mr-3">
        <Button
          onClick={e => {
            setNotes(null);
            setIsNote(true);
            fetchNotes();
          }}
          className={`btn btngroup-item m-0 ${isNote ? 'active' : ''}`}
          type="button"
        >
          Notes
        </Button>
        <Button
          onClick={e => {
            setNotes(null);
            setIsNote(false);
            fetchHistory();
          }}
          className={`btn btngroup-item m-0 ${isNote ? '' : 'active'}`}
          type="button"
        >
          History
        </Button>
      </div>
      <div
        className="py-6 px-4 mt-3"
        style={{ minWidth: '40%', minHeight: '500px' }}
      >
        {notes ? (
          notes?.length === 0 ? (
            <div className="py-6 text-center">No Results Found.</div>
          ) : (
            notes?.map((note, index) => {
              return (
                <div
                  key={index}
                  className="clearfix py-2 border-bottom border-gray-300 break-word"
                >
                  <div className="float-left col-7 d-flex">
                    {isNote ? note.note : note.changes}
                  </div>
                  <div className="float-left col-3 d-flex flex-justify-center">
                    {isNote ? note.created_at : note.date}
                  </div>
                  <div className="float-left col-2 css-truncate css-truncate-target pr-1">
                    {note?.added_by?.username ? note.added_by?.username : ''}
                  </div>
                </div>
              );
            })
          )
        ) : (
          <div className="py-6">
            <Loading show label="Loading Notes / History..." />
          </div>
        )}
      </div>
    </BasicModal>
  );
};

NotesHistoryModel.propTypes = {
  datasetId: PropTypes.number,
  showHistory: PropTypes.bool,
  type: PropTypes.string,
  onClose: PropTypes.func,
};

NotesHistoryModel.defaultProps = {
  showHistory: false,
  type: 'dataset',
};

export default NotesHistoryModel;
