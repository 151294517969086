import React from 'react';
const QuestionMarkIcon = ({ tooltipText }) => {
  return (
    <span
      className="ti ti-question-mark pl-2"
      style={{ fontSize: 'small', verticalAlign: 'text-top' }}
      data-tip={tooltipText}
    ></span>
  );
};

export default QuestionMarkIcon;
