import axios, { Axios } from 'axios';
var source;
var keywordDataCache = {};

class KeywordPopoverAPI extends Axios {
  getDescription = async(keywordId) => {
    if (keywordDataCache[keywordId]) {
      return keywordDataCache[keywordId];
    }
    if (source) {
      source.cancel();
    }
    const CancelToken = axios.CancelToken;
    source = CancelToken.source();
    return await axios({
      cancelToken: source.token,
      method: 'get',
      url: `/api/lookup/keyword/${keywordId}/`,
      headers: { 'Content-Type': 'application/json' },
    })
      .then(response => { 
        keywordDataCache[keywordId] = response.data
        return response.data
      })
      .catch(error => ({ error }));
  }
}

const KeywordPopoverService = new KeywordPopoverAPI();

export default KeywordPopoverService;
