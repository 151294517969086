import React from 'react';
import PropTypes from 'prop-types';
import ReactJsonViewCompare from 'react-json-view-compare';
import { AnchorWithoutLink, BasicModal } from '@tuva-ui/components/';

const JSONcompareModel = ({ oldMetadata, newMetadata, datasetName, date, onClose }) => {
  return (
    <BasicModal
      isOpen={true}
      className="popup popup-large"
      onClose={e => onClose()}
    >
      <BasicModal.Header
        headerText={'Dataset Metadata'}
        icon={
          <AnchorWithoutLink
            className="mr-5 p1 no-underline"
            onClick={e => onClose()}
          >
            <i className="ti ti-arrow-left" />
            back
          </AnchorWithoutLink>
        }
      />

      <div
        className="py-3 px-4"
        style={{ minWidth: '40%', minHeight: '500px' }}
      >
        <div>{datasetName}</div>
        <div>{date}</div>
        <ReactJsonViewCompare oldData={oldMetadata} newData={newMetadata} />
      </div>
    </BasicModal>
  );
};

JSONcompareModel.propTypes = {
  oldMetadata: PropTypes.object,
  newMetadata: PropTypes.object,
  onClose: PropTypes.func,
};

export default JSONcompareModel;
