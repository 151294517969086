const TYPE = {
  DELETE: 'delete assigment',
  ASSIGNMENT: 'assign assignment',
  GRADE: 'grade assignment',
};

let getGoogleGrantAccessText = function (type, object_type, app_name) {
  let text = '';
  if (type === TYPE.ASSIGNMENT) {
    text = `Looks like ${app_name} doesn't have access to post this ${object_type}
      to your Google Classroom. To ensure your students see this ${object_type}
      on Google Classroom, please allow ${app_name} to post to your Google Classroom.`;
  } else if (type === TYPE.GRADE) {
    text = `Looks like ${app_name} doesn't have access to post this grade to your Google
          Classroom. To ensure your students see receive their grade on Google
          Classroom, please allow ${app_name} to post to your Google Classroom.`;
  } else if (type === TYPE.DELETE) {
    text = `Looks like ${app_name} doesn't have access to delete this ${object_type} from
          your Google Classroom. To ensure it's removed from your Google
          Classroom, please grant ${app_name} access to your Google Classroom.`;
  }
  return text;
};

export { TYPE, getGoogleGrantAccessText };
