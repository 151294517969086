import axios from 'axios';

class ForkDatasetService {
  getForkedDatasets(unique_code) {
    return axios
      .get(`/api/user-dataset/${unique_code}/forks/`)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        return;
      });
  }

  forkDataset(datasetId) {
    return axios({
      method: 'post',
      url: `/mydatasets/fork/`,
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      },
      data: { dataset: datasetId },
    })
      .then(response => ({ response: response.data }))
      .catch(error => ({ error }));
  }
}

const ForkDatasetAPI = new ForkDatasetService();

export default ForkDatasetAPI;
