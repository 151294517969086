import React, { Component } from 'react';
import styled from 'styled-components';
import TuvaModal from '../TuvaModal';

const ZoomOut = styled.div`
  cursor: zoom-out;
  text-align: center;
`;

export const customImgRegex = /(!)\[(.*?)\]\[(.*?)\]\((.*?)\)/;

class Figure extends Component {
  state = {
    modalIsOpen: false,
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };
  render() {
    var imgArr = customImgRegex.exec(this.props.data);
    var altText = imgArr[2];
    var _size = imgArr[3];
    var imageUrl = imgArr[4];

    // Check slignment
    var alignStyles = { marginLeft: 'auto', marginRight: 'auto' };
    var size;
    if (_size.indexOf(':') > 1) {
      size = _size.substring(0, _size.indexOf(':'));
      var align = _size.substring(_size.indexOf(':') + 1);
      switch (align) {
        case 'l':
          alignStyles.marginLeft = 0;
          break;
        case 'r':
          alignStyles.marginRight = 0;
          break;
        case 'lf':
          alignStyles.marginLeft = 0;
          alignStyles.marginRight = 32;
          break;
        case 'rf':
          alignStyles.marginLeft = 32;
          alignStyles.marginRight = 0;
          break;
        default:
          break;
      }
    } else {
      size = _size;
    }
    size = size.toLowerCase().split('x');

    var width = size[0] || 0,
      height = size[1] || 0,
      title = '';

    // Fix numbers
    function getNumberFromString(val) {
      if (isNaN(val)) {
        return 0;
      } else {
        return parseInt(val);
      }
    }
    width = getNumberFromString(width);
    height = getNumberFromString(height);

    if (imageUrl.indexOf('"') > 0) {
      title = imageUrl.substring(
        imageUrl.indexOf('"') + 1,
        imageUrl.length - 1,
      );
      imageUrl = imageUrl.substring(0, imageUrl.indexOf('"'));
    }

    return (
      <React.Fragment>
        <figure
          style={{
            textAlign: 'center',
            maxWidth: '100%',
            width: width > 0 ? width + 'px' : 'auto',
            ...alignStyles,
          }}
        >
          <img
            src={imageUrl.trim()}
            alt={altText}
            className="border border-green-300"
            style={{
              maxWidth: '100%',
              width: width > 0 ? width + 'px' : 'auto',
              height: height > 0 ? height + 'px' : 'auto',
              display: 'block',
            }}
            onClick={e => this.setState({ modalIsOpen: true })}
          />
          {title && (
            <figcaption
              className="bg-blue-light text-black text-center p-2"
              style={{
                boxSizing: 'border-box',
                marginBottom: '10px',
                maxWidth: '100%',
                width: width > 0 ? width + 'px' : 'auto',
              }}
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            ></figcaption>
          )}
        </figure>
        <TuvaModal
          isOpen={this.state.modalIsOpen}
          onClose={this.closeModal}
          style={{ maxWidth: '90%', padding: 0 }}
        >
          <ZoomOut>
            <img
              src={imageUrl.trim()}
              alt={altText}
              title={title}
              style={{
                maxWidth: '100%',
                display: 'block',
              }}
              onClick={this.closeModal}
            />
          </ZoomOut>
        </TuvaModal>
      </React.Fragment>
    );
  }
}

export default Figure;
