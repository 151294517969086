import axios from 'axios';

class FeedbackService {
  submitFeedback(data) {
    return axios({
      method: 'post',
      url: `/api/misc/common-notify/`,
      headers: { 'Content-Type': 'application/json' },
      data,
    })
      .then(response => ({ response: response.status }))
      .catch(error => ({ error }));
  }
}

const FeedbackAPI = new FeedbackService();

export default FeedbackAPI;
