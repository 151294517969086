import React, { Component } from 'react';
import TuvaModal from '../TuvaModal';

const Header = ({ headerText, icon }) => {
  return (
    <div className="popup-header border-bottom py-3 px-4 border-gray-300">
      <h5>
        {icon && <span className="pr-1">{icon}</span>}
        {headerText}
      </h5>
    </div>
  );
};

const Body = ({ bgColor, children }) => {
  return (
    <div className={`popup-body py-3 px-4 ${bgColor || ''}`}>{children}</div>
  );
};

const Footer = ({ children }) => {
  return (
    <div className="poup-footer footer-link p-4 p2 py-2 text-center mt-0">
      {children}
    </div>
  );
};

class BasicModal extends Component {
  static Header = Header;
  static Body = Body;
  static Footer = Footer;
  static SIZES = TuvaModal.SIZES;
  render() {
    let { type, className } = this.props;
    return (
      <TuvaModal
        isForm={'BasicModal'}
        onClose={this.props.onClose}
        isOpen={this.props.isOpen}
        style={{ padding: 0 }}
        type={type || TuvaModal.SIZES.MEDIUM}
        className={className ? className + ' basic-model' : 'basic-model'}
      >
        {this.props.children}
      </TuvaModal>
    );
  }
}

export default BasicModal;
