import axios from 'axios';
import { RestService } from '@tuva-ui/components';

class District extends RestService {
  getDistrictUnitContent(slug) {
    return axios({
      method: 'Get',
      url: `/api/analytics2/district/${slug}/district-unit`,
      headers: { 'Content-Type': 'application/json' },
    })
      .then(response => response.data)
      .catch(error => {
        return error.response;
      });
  }
}

const DistrictService = new District();

export default DistrictService;
