import React, { Component } from 'react';
import TuvaModal from '../TuvaModal';

class ZoomableImage extends Component {
  state = {
    modalIsOpen: false,
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };
  render() {
    const style = { cursor: 'zoom-in', ...this.props.style };
    return (
      <React.Fragment>
        <img
          src={this.props.src}
          alt={this.props.alt}
          title={this.props.title}
          style={style}
          width={this.props.width}
          onClick={e => this.setState({ modalIsOpen: true })}
        />
        <TuvaModal
          isOpen={this.state.modalIsOpen}
          onClose={this.closeModal}
          style={{ maxWidth: '90%', padding: 0 }}
        >
          <img
            src={this.props.src}
            alt={this.props.alt}
            title={this.props.title}
            style={{
              maxWidth: '100%',
              display: 'block',
              cursor: 'zoom-out',
            }}
            onClick={this.closeModal}
          />
        </TuvaModal>
      </React.Fragment>
    );
  }
}

export default ZoomableImage;
