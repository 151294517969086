import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './Markdown.scss';
import showdown from 'showdown';
import './MarkdownPlugin';
import ZoomableImage from '../ZoomableImage';

import HtmlToReact from 'html-to-react';
import { RadioGroup, Radio } from 'react-radio-group';
import CheckboxGroup from 'react-checkbox-group';
import Figure, { customImgRegex } from './Figure';
import MathJax from 'react-mathjax';
import QuestionsUtil from './QuestionsUtil';
import GraphType from './GraphType';
import Util from '../Util';
import Popover from './KeywordPopover/KeywordPopover';
import LanguageSupport from './LanguageSupport';

const converter = new showdown.Converter({ extensions: ['tuva'] });

export const MarkdownInline = props => {
  if (!props.value) {
    return <span></span>;
  }
  const html = converter
    .makeHtml(props.value)
    .replace('<p>', '')
    .replace('</p>', '');
  var processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);
  var processingInputs = [
    {
      shouldProcessNode: function (node) {
        return (
          node.type === 'text' &&
          node.parent &&
          node.parent.attribs?.class === 'mathjax'
        );
      },
      processNode: function (node, children) {
        // For Mathjax
        if (node.parent && node.parent.attribs.class === 'mathjax') {
          return (
            <MathJax.Provider>
              <MathJax.Node inline formula={node.data} />
            </MathJax.Provider>
          );
        }
      },
    },
    {
      // Anything else
      shouldProcessNode: function (node) {
        return true;
      },
      processNode: processNodeDefinitions.processDefaultNode,
    },
  ];

  var htmlToReactParser = new HtmlToReact.Parser();
  return htmlToReactParser.parseWithInstructions(
    html,
    function () {
      return true;
    },
    processingInputs,
  );
};

class Markdown extends Component {
  formInputs = [];

  onMessageCallback = e => {
    if (typeof e.data !== 'object') {
      try {
        const data = JSON.parse(e.data);
        if ('plotView' in data || 'columnIds' in data) {
          // Ready to update plot
          this.props.onSimulation && this.props.onSimulation(data);
        }
      } catch (err) {
        // Fail siliently when there are error whil cosuming data from message callback
      }
    }
  };

  componentDidMount = () => {
    // Listen to get data from iframe
    window.addEventListener('message', this.onMessageCallback, false);
  };

  componentWillUnmount = () => {
    // Remove listener
    window.removeEventListener('message', this.onMessageCallback, false);
  };

  onValChange = (id, value, meta) => {
    const question = this.props.questions.find(q => q.questionId === id);
    const answers = this.props.answers;
    let isCorrect = null;
    if (question.answer) {
      if (question.type === 'checkbox') {
        isCorrect = question.answer.sort() + '' === value.sort() + '';
        if (value.length === 0) {
          value = null;
        }
      } else if (question.type === 'graph') {
        isCorrect = null;
      } else {
        isCorrect = question.answer === value;
      }
    }
    // Form the answers
    answers[id] = { userAnswer: value };
    if (typeof isCorrect === 'boolean') {
      answers[id].isCorrect = isCorrect;
    }
    // Add preview to answers
    if (question.type === 'graph') {
      answers[id].preview = meta;
    }

    this.props.setAnswer(answers);
  };

  state = {
    validationRequired: false,
    isSolutionClicked: [],
  };

  isValid = () => {
    var isValid = true;
    const noOfQuestions = this.props.questions?.length || 0;
    for (let index = 0; index < noOfQuestions; index++) {
      const q = this.props.questions[index];
      if (!this.props.answers[q.questionId]?.userAnswer) {
        if (this.formInputs[q.questionId]) {
          setTimeout(() => {
            this.formInputs[q.questionId].focus();
          }, 50);
        }
        isValid = false;
        break;
      }
    }
    this.setState({
      validationRequired: true,
    });
    return isValid;
  };

  handleSubmit = e => {
    e.preventDefault();
  };

  render() {
    this.buildDynamicComponentFromMarkdown();

    let isTextOnly = true;
    const { questions } = this.props;
    if (questions && Object.keys(questions).length > 0) {
      isTextOnly = false;
    }

    const _className = this.props.className ? ' ' + this.props.className : '';
    // Render without form tag when a page doesn't have any questions
    if (isTextOnly) {
      return (
        <div className={'markdown-wrapper' + _className}>
          {this.dynamicMarkdownElement}
        </div>
      );
    } else {
      return (
        <form
          ref={el => (this.form = el)}
          onSubmit={this.handleSubmit}
          autoComplete="off"
          className={'markdown-wrapper' + _className}
        >
          <fieldset disabled={!!this.props.isDisabled}>
            {this.dynamicMarkdownElement}
          </fieldset>
        </form>
      );
    }
  }

  getActualAnswer(q) {
    if (!q.answer) {
      return '';
    }

    if (q.type === 'checkbox' || q.type === 'radio') {
      // return Util.getStringFromIndex(q.options, q.answer, " <br>");
      return Util.getAlphabetFromNumbers(q.answer, ', ');
    } else {
      return q.answer;
    }
  }

  showSolution = (e, questionId) => {
    e.preventDefault();
    var temp = this.state.isSolutionClicked;
    if (temp[questionId]) {
      temp[questionId] = false;
    } else {
      temp[questionId] = true;
    }
    this.setState({ isSolutionClicked: temp });
  };

  getSolution(questionId) {
    const answerKey = this.props.answerKey;
    const question = this.props.questions.find(
      q => q.questionId === questionId,
    );
    const answer = answerKey[questionId] ? answerKey[questionId].answer : '';
    const description = answerKey[questionId]
      ? answerKey[questionId].description
      : null;

    if (question.type === 'radio') {
      return (
        <div>
          <div className="d-flex">
            <div>{Util.getAlphabetFromNumber(answer - 1)}.</div>
            <div className="flex-1 ml-1">
              <MarkdownInline value={question.options[answer - 1]} />
            </div>
          </div>
          {description && (
            <div className="mt-2">
              <MarkdownInline value={description} />
            </div>
          )}
        </div>
      );
    } else if (question.type === 'checkbox') {
      return (
        <div>
          {answer
            .sort((a, b) => (a > b ? 1 : -1))
            .map((answer, id) => (
              <div key={id}>
                <div className="d-flex">
                  <div>{Util.getAlphabetFromNumber(answer - 1)}.</div>
                  <div className="flex-1 ml-1">
                    <MarkdownInline value={question.options[answer - 1]} />
                  </div>
                </div>
              </div>
            ))}
          {description && (
            <div className="mt-2">
              <MarkdownInline value={description} />
            </div>
          )}
        </div>
      );
    } else if (question.type === 'text' || question.type === 'textarea') {
      return (
        <div>
          <span>
            <MarkdownInline value={answer} />
          </span>
          {description && (
            <div className="mt-2">
              <MarkdownInline value={description} />
            </div>
          )}
        </div>
      );
    } else if (question.type === 'graph') {
      return (
        <div>
          <ZoomableImage src={answer} alt="" width="100%" />
          {description && (
            <div className="mt-2">
              <MarkdownInline value={description} />
            </div>
          )}
        </div>
      );
    }
  }

  buildDynamicComponentFromMarkdown() {
    const { text } = QuestionsUtil.extractQuestions(this.props.value);
    const html = converter.makeHtml(text);
    const questions = this.props.questions;
    const answers = this.props.answers;
    const answerKey = this.props.answerKey;
    const self = this;

    var processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);
    var processingInputs = [
      {
        shouldProcessNode: function (node) {
          // Handling tuva-icon clieck from content panel
          // Only allow tuva icon tags which requires event listeners
          if (
            node.type === 'tag' &&
            node.attribs.class &&
            node.attribs.class.indexOf('markdown-icon-events-binder') > 0
          ) {
            return true;
          }
          // Handling language support accodion view
          if (
            node.type === 'tag' &&
            node.attribs.style &&
            node.attribs.style.indexOf('accordion') >= 0
          ) {
            return true;
          }

          // Process text
          return (
            node.type === 'text' &&
            ((node.data.indexOf('--:') >= 0 && node.data.indexOf(':--') >= 0) ||
              (node.parent && node.parent.attribs?.class === 'mathjax') ||
              (node.data.indexOf('!') === 0 && customImgRegex.test(node.data)))
          );
        },
        processNode: function (node, children) {
          // Register clieck event for the tuva-icons
          if (node.type === 'tag') {
            const className = node.attribs.class;
            const style = node.attribs.style;
            const iconKey = node.attribs['data-key'];
            if (
              className &&
              className.indexOf('markdown-icon-events-binder') > 0
            ) {
              return (
                <i
                  className={className}
                  onClick={e => {
                    self.props.onIconClick && self.props.onIconClick(iconKey);
                  }}
                >
                  <span className={children[0].props.className}>
                    {children[0].props.children}
                  </span>
                </i>
              );
            }
            if (style && style.indexOf('accordion') >= 0) {
              return (
                <LanguageSupport
                  content={children}
                  cssString={node?.attribs?.style}
                />
              );
            }
          }
          // For Mathjax
          if (node.parent && node.parent.attribs.class === 'mathjax') {
            return (
              <MathJax.Provider>
                <MathJax.Node inline formula={node.data} />
              </MathJax.Provider>
            );
          }

          // For custom image with zoom
          if (node.data.indexOf('!') === 0 && customImgRegex.test(node.data)) {
            return <Figure data={node.data}></Figure>;
          }

          // For questions
          if (node.data.indexOf('--:') >= 0 && node.data.indexOf(':--') >= 0) {
            const questionId = node.data.substring(
              node.data.indexOf(':') + 1,
              node.data.lastIndexOf(':'),
            );

            const question = questions?.find(q => q.questionId === questionId);
            const answer = answers ? answers[questionId] : null;
            if (!question) {
              // Return data if nothing matches
              return node.data;
            }
            const showInvalidInput =
              self.state.validationRequired && !(answer && answer.userAnswer);
            let resultEle = '';

            // Check and ignore the default placeholder text
            if (question.placeHolder === 'PLACEHOLDER_TEXT') {
              question.placeHolder = '';
            }

            // Checkbox
            if (question.type === 'checkbox') {
              resultEle = (
                <CheckboxGroup
                  name={questionId}
                  value={answer?.userAnswer || []}
                  onChange={values => self.onValChange(questionId, values)}
                >
                  {Checkbox => (
                    <>
                      <div
                        className={
                          'options-wrapper' +
                          (showInvalidInput ? ' invalid' : '')
                        }
                      >
                        <ul
                          className="question-options"
                          style={{ listStyleType: 'upper-alpha' }}
                        >
                          {question.options.map((option, id) => (
                            <li
                              key={id}
                              ref={el => {
                                if (id === 0 && el) {
                                  self.formInputs[questionId] =
                                    el.getElementsByTagName('input')[0];
                                }
                              }}
                            >
                              <label>
                                <div>
                                  <Checkbox
                                    className="form-control"
                                    value={id + 1}
                                  />{' '}
                                  <span>
                                    <MarkdownInline value={option} />
                                  </span>
                                </div>
                              </label>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </>
                  )}
                </CheckboxGroup>
              );
            } else if (question.type === 'radio') {
              resultEle = (
                <RadioGroup
                  className={
                    'options-wrapper' + (showInvalidInput ? ' invalid' : '')
                  }
                  name={questionId}
                  selectedValue={answer?.userAnswer}
                  onChange={value => self.onValChange(questionId, value)}
                >
                  <ul
                    className="question-options"
                    style={{ listStyleType: 'upper-alpha' }}
                  >
                    {question.options.map((option, id) => (
                      <li key={id}>
                        <label>
                          <Radio
                            ref={el => {
                              if (id === 0) {
                                self.formInputs[questionId] =
                                  ReactDOM.findDOMNode(el);
                              }
                            }}
                            className="form-control"
                            value={id + 1}
                            required
                          />{' '}
                          <span>
                            <MarkdownInline value={option} />
                          </span>
                        </label>
                      </li>
                    ))}
                  </ul>
                </RadioGroup>
              );
            } else if (question.type === 'text') {
              resultEle = (
                <input
                  id={questionId}
                  ref={el => (self.formInputs[questionId] = el)}
                  type="input"
                  required
                  className={
                    'form-control col-12' +
                    (showInvalidInput ? ' border-red' : '')
                  }
                  placeholder={question.placeHolder || ''}
                  value={answer?.userAnswer}
                  onChange={e => self.onValChange(e.target.id, e.target.value)}
                />
              );
            } else if (question.type === 'textarea') {
              resultEle = (
                <textarea
                  id={questionId}
                  ref={el => (self.formInputs[questionId] = el)}
                  rows={question.rows}
                  required
                  className={
                    'form-control col-12' +
                    (showInvalidInput ? ' border-red' : '')
                  }
                  placeholder={question.placeHolder || ''}
                  value={answer?.userAnswer}
                  onChange={e => self.onValChange(e.target.id, e.target.value)}
                ></textarea>
              );
            } else if (question.type === 'graph') {
              resultEle = (
                <GraphType
                  id={questionId}
                  isInvalidInput={showInvalidInput}
                  getPlotState={self.props.getPlotState}
                  getPlotPreview={self.props.getPlotPreview}
                  onValueChange={(id, value, preview) => {
                    self.onValChange(id, value, preview);
                  }}
                  value={answer?.userAnswer}
                  preview={answer?.preview}
                  disabled={self.props.isDisabled}
                  ref={el => (self.formInputs[questionId] = el)}
                />
              );
            }

            // Get correct answer from question.answer or answer.answer
            const correctAnswer = question.answer;

            return (
              <div
                className={
                  'question-wrapper' +
                  (self.props.showAnswer &&
                  correctAnswer &&
                  question.type !== 'graph'
                    ? ' border rounded-1 p-2 ' +
                      (answer?.isCorrect ? 'correct-answer' : 'wrong-answer')
                    : '')
                }
              >
                <div className="question">
                  <div className="question-label">
                    {self.props.showAnswer &&
                      correctAnswer &&
                      question.type !== 'graph' &&
                      !answer?.isCorrect && (
                        <i className="fa fa-close pr-1 text-red"></i>
                      )}
                    {self.props.showAnswer &&
                      correctAnswer &&
                      question.type !== 'graph' &&
                      answer?.isCorrect && (
                        <i className="fa fa-check pr-1 text-green"></i>
                      )}
                    <span>
                      <MarkdownInline value={question.question} />
                      {answerKey &&
                        answerKey[questionId] &&
                        answerKey[questionId].answer && (
                          <span className="mb-2">
                            <span
                              className={
                                'ml-2 solution-wrapper first btn text-black' +
                                (self.state.isSolutionClicked[questionId]
                                  ? ' btn-gold'
                                  : ' bg-yellow-2')
                              }
                              style={{ padding: '2px 6px 3px' }}
                              onClick={e => self.showSolution(e, questionId)}
                            >
                              <i className="ti ti-bulb"></i>
                            </span>
                          </span>
                        )}
                    </span>
                  </div>
                  {resultEle}
                  {self.props.showAnswer &&
                    correctAnswer &&
                    question.type !== 'graph' &&
                    !answer?.isCorrect && (
                      <div>
                        <b>Correct Answer:</b>{' '}
                        <span
                          dangerouslySetInnerHTML={{
                            __html: self.getActualAnswer(question),
                          }}
                        ></span>
                      </div>
                    )}
                  {self.state.isSolutionClicked[questionId] && (
                    <div
                      className={
                        'rounded-2 p-2 bg-yellow-1 ' +
                        (question.type === 'graph' ? ' mt-2' : '')
                      }
                    >
                      <div className="d-flex flex-items-start flex-justify-between">
                        <div className="d-flex">
                          <i className="ti ti-bulb mr-2"></i>
                          <h6 className="font-weight-bold">
                            <span>Solution</span>
                          </h6>
                        </div>
                        <span
                          onClick={e => self.showSolution(e, questionId)}
                          className="cursor-pointer ti ti-close p4 px-2"
                        ></span>
                      </div>
                      {self.getSolution(questionId)}
                    </div>
                  )}
                </div>
              </div>
            );
          }

          // Return data if nothing matches
          return node.data;
        },
      },
      {
        shouldProcessNode: function (node) {
          return (
            node.type === 'text' &&
            node.parent &&
            node.parent.attribs?.class === 'keyword-popup'
          );
        },
        processNode: function (node, children) {
          // For wikipopup
          if (node.parent && node.parent.attribs.class === 'keyword-popup') {
            return (
              <>
                <Popover keyword_id={node.parent.attribs['keyword_id']}>
                  {node.data}
                </Popover>
              </>
            );
          }
        },
      },
      {
        // Anything else
        shouldProcessNode: function (node) {
          return true;
        },
        processNode: processNodeDefinitions.processDefaultNode,
      },
    ];

    var HtmlToReactParser = HtmlToReact.Parser;
    var htmlToReactParser = new HtmlToReactParser();
    var isValidNode = function () {
      return true;
    };
    this.dynamicMarkdownElement = htmlToReactParser.parseWithInstructions(
      html,
      isValidNode,
      processingInputs,
    );
  }
}

export default Markdown;
