import React from 'react';
import PropTypes from 'prop-types';
import { AlertModal } from '@tuva-ui/components/';

const DeleteModal = ({ title, isOpen, onSubmit, onClose }) => {
  return (
    <AlertModal
      isOpen={isOpen}
      onClose={() => onClose()}
      submitText="Remove"
      onSubmit={() => onSubmit()}
      onCancel={() => onClose()}
      type={AlertModal.TYPES.WARNING}
    >
      <AlertModal.Header headerText={`Delete: ${title}`} />
      <AlertModal.Body>
        <p className="text-gray pb-3">
          Are you sure you want to delete <b>{title}</b>?
        </p>
      </AlertModal.Body>
    </AlertModal>
  );
};

DeleteModal.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

export default DeleteModal;
