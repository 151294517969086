import React from 'react';
import { MARKDOWN_AUTOCOMPLETE_TYPES } from '../Constants';
import Button from '../Button/Button';

export default function MarkdownHeader({ OnSelect, triggerAutocomplete }) {
  return (
    <div className="border-bottom border-gray-500 pl-1">
      <Button title="Bold" onClick={e => OnSelect('bold')}>
        <i className="ti ti-bold d-inline-flex"></i>
      </Button>
      <Button title="Italic" onClick={e => OnSelect('italic')}>
        <i className="ti ti-italic d-inline-flex"></i>
      </Button>
      <Button
        className="ml-1"
        title="Unordered List"
        onClick={e => OnSelect('UnorderedList')}
      >
        <i className="ti ti-list-ul d-inline-flex"></i>
      </Button>
      <Button title="Ordered List" onClick={e => OnSelect('OrderedList')}>
        <i className="ti ti-list-ol d-inline-flex"></i>
      </Button>
      <Button
        className="border-right"
        title="Break to Next Line"
        onClick={e => OnSelect('NewLine')}
      >
        <i className="ti ti-newline d-inline-flex"></i>
      </Button>
      <Button
        title="Tuva Attributes"
        onClick={e =>
          triggerAutocomplete(MARKDOWN_AUTOCOMPLETE_TYPES.ATTRIBUTES)
        }
      >
        <i className="ti ti-attributes d-inline-flex"></i>
      </Button>
      <Button
        title="Alert"
        onClick={e =>
          triggerAutocomplete(MARKDOWN_AUTOCOMPLETE_TYPES.ALERTICONS)
        }
      >
        <i className="d-inline-flex ti ti-alert-stop "></i>
      </Button>
      <Button
        className="border-right"
        title="Tuva Icons"
        onClick={e => triggerAutocomplete(MARKDOWN_AUTOCOMPLETE_TYPES.ICONS)}
      >
        <i className="ti ti-icon d-inline-flex"></i>
      </Button>
      <Button title="Link" onClick={e => OnSelect('link')}>
        <i className="d-inline-flex ti ti-link"></i>
      </Button>
      <Button title="Image" onClick={e => OnSelect('img')}>
        <i className="d-inline-flex ti ti-img"></i>
      </Button>
      <Button title="Youtube" onClick={e => OnSelect('youtube')}>
        <i className="d-inline-flex ti ti-youtube"></i>
      </Button>
      <Button title="Vimeo" onClick={e => OnSelect('vimeo')}>
        <i className="d-inline-flex ti ti-vimeo"></i>
      </Button>
    </div>
  );
}
