import { Button } from '@tuva-ui/components/';

function GraphType() {
  return (
    <div className={'border text-center graph-question border-dashed my-2'}>
      <div style={{ width: '100%' }} className="bg-white d-table">
        <div
          className="d-table-cell v-align-middle"
          style={{ height: '200px' }}
        >
          <span>Save Your Work</span>
        </div>
      </div>
      <div
        className="p-2 bg-gray-light border-top"
        style={{ position: 'relative' }}
      >
        <Button
          className={'btn text-normal btn-primary'}
          type="button"
          disabled={true}
        >
          Save
        </Button>
      </div>
    </div>
  );
}

export default GraphType;
