import React, { useCallback, useEffect, useState } from 'react';
import {
  Form,
  BasicModal,
  Input,
  TuvaModal,
  TuvaToast,
  Loading,
} from '@tuva-ui/components/';
import ModalService from '../services/Modal.service';
import Constants from '../util/Constants';

const SUBSCRIPTION_TYPES = [
  {
    subscriptionName: 'Tuva Premium',
    value: 'premium',
    display: 'Tuva Premium',
  },
  { subscriptionName: 'Tuva Jr.', value: 'jr', display: 'Tuva Jr.' },
  {
    subscriptionName: 'Assessments',
    value: 'assessments',
    display: 'Assessments',
  },
];

function PricingModal({ onCancel, subscription }) {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState();
  const [roles, setRoles] = useState();
  const [subscriptionPlan, setSubscriptionPlan] = useState();
  const [interestedContent, setInterestedContent] = useState();

  let getRoleKey = value => {
    if (value) {
      let index = roles.findIndex(o => o['role_name'] === value);
      return roles[index]['role_value'];
    }
  };

  let populateInterestedContent = value => {
    let content = [];
    if (value === 'Per School / Campus Plan') {
      content.push(Constants.INTERESTED_CONTENT_OPTIONS[3]);
    } else if (value === 'Districtwide Plan') {
      content.push(Constants.INTERESTED_CONTENT_OPTIONS[3]);
      content.push(Constants.INTERESTED_CONTENT_OPTIONS[4]);
    }
    setInterestedContent(content);
  };

  let getSubscriptionTitle = () => {
    let subscriptionTitle = '';
    if (subscriptionPlan === 'Per Teacher Plan') {
      subscriptionTitle = 'For Teachers';
    } else if (subscriptionPlan === 'Per School / Campus Plan') {
      subscriptionTitle = 'For Per School / Campus Plan';
    } else if (subscriptionPlan === 'Districtwide Plan') {
      subscriptionTitle = 'For Districtwide Plan';
    }
    return subscriptionTitle;
  };

  let whoamiAPI = useCallback(() => {
    return new Promise((resolve, reject) => {
      ModalService.whoami()
        .then(userInfo => {
          resolve(userInfo);
        })
        .catch(err => {
          resolve(null);
          console.log('Error while fetching user details', err);
        });
    });
  }, []);

  let getRoles = useCallback(() => {
    return new Promise((resolve, reject) => {
      ModalService.getRoles()
        .then(function (data) {
          resolve(data);
        })
        .catch(function (err) {
          resolve(null);
          console.log('Error while fetching roles', err);
        });
    });
  }, []);

  useEffect(() => {
    const fetchRoles = getRoles();
    const fetchUser = whoamiAPI();

    Promise.all([fetchRoles, fetchUser]).then(responses => {
      if (responses[0]) {
        setRoles(responses[0]);
      }
      if (responses[1]) {
        setUser(responses[1]);
      }
      setIsLoading(false);
    });
  }, [whoamiAPI, getRoles]);

  const onSubmit = useCallback(
    data => {
      let index = SUBSCRIPTION_TYPES.findIndex(
        o => o['value'] === data['subscription'],
      );
      data['contentInterested'] = data['contentInterested']?.toString();
      data['subscription'] = SUBSCRIPTION_TYPES[index]['display'];

      ModalService.saveRequestQuote(data)
        .then(function (response) {
          if (response.fail === 1) {
            TuvaToast.error(
              'We encountered an issue while submitting your request. Please try again later.',
            );
          } else {
            TuvaToast.info(
              "Thank you for your request! We'll get in touch soon.",
            );
          }
          onCancel();
        })
        .catch(function (err) {
          TuvaToast.error(
            'We encountered an issue while submitting your request.',
          );
        });
    },
    [onCancel],
  );

  return (
    <BasicModal type={TuvaModal.SIZES.MEDIUM} isOpen onClose={e => onCancel()}>
      <BasicModal.Header headerText="Request a Quote for a Tuva Subscription" />
      <BasicModal.Body>
        {!isLoading ? (
          <div className="col-12">
            <Form
              submitText={'Submit'}
              cancelText="Cancel"
              layout={Form.LAYOUT.HORIZONTAL}
              labelCol={'col-4'}
              inputCol={'col-8'}
              onSubmit={onSubmit}
              onCancel={onCancel}
              isDynamic={true}
            >
              <Form.Item
                label="Name*"
                name="name"
                defaultValue={
                  user ? user.first_name + ' ' + user.last_name : ''
                }
                rules={[{ required: true }]}
              >
                <Input.Text />
              </Form.Item>
              <Form.Item
                label="Email*"
                name="email"
                defaultValue={user?.email}
                rules={[{ required: true }]}
              >
                <Input.Text />
              </Form.Item>
              <Form.Item
                label="Organization*"
                name="organization"
                defaultValue={user?.organization}
                rules={[{ required: true }]}
              >
                <Input.Text />
              </Form.Item>
              <Form.Item
                label="Role*"
                name="role"
                defaultValue={getRoleKey(user?.role)}
                values={user?.role}
                rules={[{ required: true }]}
              >
                <Input.Select
                  placeholder=" "
                  valueKey="role_name"
                  displayKey="role_name"
                  options={roles}
                />
              </Form.Item>
              <Form.Item
                label="Phone*"
                name="phone"
                rules={[{ required: true }]}
              >
                <Input.Text />
              </Form.Item>
              <Form.Item
                label="Subscription Type*"
                name="subscription"
                defaultValue={subscription}
                values={subscription}
                rules={[{ required: true }]}
              >
                <Input.Select
                  placeholder=" "
                  options={SUBSCRIPTION_TYPES}
                  valueKey="value"
                  displayKey="subscriptionName"
                />
              </Form.Item>

              <Form.Item
                label="Choose Plan*"
                name="plan"
                rules={[{ required: true }]}
              >
                <Input.RadioGroup type={'vertical'} value={subscriptionPlan}>
                  {Constants.SUBSCRIPTION_OPTIONS.map(function (item, i) {
                    return (
                      <label className={'text-normal'} key={i}>
                        <input
                          className="mr-2"
                          type="radio"
                          checked={subscriptionPlan === item.value}
                          onChange={e => {
                            setSubscriptionPlan(item.value);
                            populateInterestedContent(item.value);
                          }}
                          value={item.value}
                          name="plan"
                        />
                        {item.value}
                      </label>
                    );
                  })}
                </Input.RadioGroup>
              </Form.Item>
              <div className="form-group d-flex mt-1 mb-3 col-12 position-relative ">
                <label className="form-item-label text-normal p2 col-4"></label>
                <div className="text-bold"> {getSubscriptionTitle()}</div>
              </div>

              {subscriptionPlan && (
                <Form.Item
                  label={`Number of ${
                    subscriptionPlan.startsWith('Per School')
                      ? 'Campuses'
                      : 'Teachers'
                  }*`}
                  name="noOfTeachers"
                  rules={[{ required: true }]}
                >
                  <Input.Text />
                </Form.Item>
              )}
              {subscriptionPlan && (
                <Form.Item
                  label="Content Area*"
                  name="contentInterested"
                  rules={[{ required: true }]}
                  defaultValue={interestedContent}
                >
                  <Input.CheckboxGroup type={'vertical'} name="contentGroup">
                    {Constants.INTERESTED_CONTENT_OPTIONS.map(option => {
                      return (
                        <Input.Checkbox
                          className={`mr-2 ${option === 'Other' ? 'mb-5' : ''}`}
                          value={option}
                          name="content"
                        />
                      );
                    })}
                  </Input.CheckboxGroup>
                </Form.Item>
              )}
              {subscriptionPlan && (
                <div className="form-group d-flex mt-1 mb-3 col-12 position-relative ">
                  <label className="form-item-label text-normal p2 col-4"></label>
                </div>
              )}
              <Form.Item label="Message" name="message">
                <Input.TextArea rows={9} />
              </Form.Item>
            </Form>
          </div>
        ) : (
          <Loading />
        )}
      </BasicModal.Body>
    </BasicModal>
  );
}

export default PricingModal;
