import React from 'react';
import PropTypes from 'prop-types';

const Gsheet = ({ url }) => {
  return (
    <a
      className="p-1"
      title={`Google Drive Spreadsheet`}
      href={url}
      target="_blank"
      rel="noreferrer"
    >
      Link to Gsheet
    </a>
  );
};

Gsheet.propTypes = {
  url: PropTypes.string,
};

export default Gsheet;
