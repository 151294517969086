import axios from 'axios';
import RestService from '../RestService';

class FilterAPI extends RestService {
  getFilters = (filterType, isFromImportApp = false) => {
    return axios({
      method: 'get',
      url:
        (isFromImportApp
          ? '/imports/filters/?filter_type='
          : '/api/misc/filters/?filter_type=') + filterType,
    })
      .then(response => ({ response: response.data.results }))
      .catch(error => ({ error }));
  };
}

const FilterService = new FilterAPI();

export default FilterService;
