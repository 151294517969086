import React, { Component } from 'react';
import BasicModal from '../BasicModal/BasicModal';
import Button from '../Button/Button';

const Body = ({
  children,
  submitText,
  cancelText,
  onCancel,
  onSubmit,
  type,
  disableButton,
}) => {
  return (
    <BasicModal.Body>
      <div className="p-3 m-2">
        {children}
        <div className="d-flex mt-4">
          {type === AlertModal.TYPES.CONFIRMATION && (
            <>
              <Button
                type="button"
                className="btn px-4 py-2 bg-white border text-gray mr-5"
                disabled={disableButton}
                onClick={e => onCancel()}
              >
                {cancelText || 'Cancel'}
              </Button>
              <Button
                type="button"
                className="btn btn-primary px-4 py-2 ml-2 mr-3"
                disabled={disableButton}
                style={{ border: '1px solid transparent' }}
                onClick={e => onSubmit()}
              >
                {submitText || 'Ok'}
              </Button>
            </>
          )}
          {type === AlertModal.TYPES.WARNING && (
            <Button
              type="button"
              className="btn px-4 py-2 bg-white border text-gray mr-6"
              disabled={disableButton}
              onClick={e => onCancel()}
            >
              {cancelText || 'Cancel'}
            </Button>
          )}
          {type !== AlertModal.TYPES.CONFIRMATION && (
            <Button
              type="button"
              className={`btn px-4 py-2 ${
                type === AlertModal.TYPES.WARNING ? 'btn-danger' : 'btn-primary'
              }`}
              disabled={disableButton}
              onClick={e => onSubmit()}
            >
              {submitText || 'Ok'}
            </Button>
          )}
        </div>
      </div>
    </BasicModal.Body>
  );
};
Body.displayName = 'Body';

const Header = ({ headerText }) => {
  return <BasicModal.Header headerText={headerText}></BasicModal.Header>;
};

class AlertModal extends Component {
  static Body = Body;
  static Header = Header;
  static TYPES = {
    SIMPLE: 'simple',
    WARNING: 'warning',
    CONFIRMATION: 'confirmation',
  };
  static SIZES = BasicModal.SIZES;

  render() {
    const {
      children,
      cancelText,
      submitText,
      onCancel,
      onSubmit,
      type,
      onClose,
      isOpen,
      size,
      disableButton,
    } = this.props;

    let newChildren = React.Children.map(children, child => {
      if (child.type.displayName == 'Body') {
        return React.cloneElement(child, {
          submitText,
          cancelText,
          type,
          onSubmit: () => onSubmit(),
          onCancel: () => onCancel(),
          disableButton,
        });
      } else {
        return child;
      }
    });

    return (
      <BasicModal
        type={size || BasicModal.SIZES.MEDIUM}
        onClose={onClose}
        isOpen={isOpen}
        style={{ padding: 0 }}
      >
        {newChildren}
      </BasicModal>
    );
  }
}

export default AlertModal;
