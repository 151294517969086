import React from 'react';
import PropTypes from 'prop-types';
import styled, {keyframes} from 'styled-components';

class Loading extends React.Component {
  render() {
    const color = this.props.theme === 'dark' ? '#FFFFFF' : '#434C5E';
    const pulse1 = keyframes`
            0%, 100% {
                opacity: 0.4;
            }
            21%, 80% {
                opacity: 1;
            }
        `;
    const pulse2 = keyframes`
            0%, 20%, 100% {
                opacity: 0.4;
            }
            41%, 80% {
                opacity: 1;
            }
        `;
    const pulse3 = keyframes`
            0%, 40%, 100% {
                opacity: 0.4;
            }
            61%, 80% {
                opacity: 1;
            }
        `;

    const Wrapper = styled.div`
      display: flex;
      flex-direction: column;
      align-items: center;
      color: ${color};
    `;

    const BubbleContainer = styled.div`
      width: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5px;
    `;

    const Bubble = styled.div`
      width: 10px;
      height: 20px;
      background-color: ${color};

      :first-child {
        animation: ${pulse1} 2s ease 0s infinite normal;
      }
      :nth-child(2) {
        animation: ${pulse2} 2s ease 0s infinite normal;
      }
      :last-child {
        animation: ${pulse3} 2s ease 0s infinite normal;
      }
    `;
    return (
      <Wrapper>
        <BubbleContainer>
          <Bubble />
          <Bubble />
          <Bubble />
        </BubbleContainer>
        <div>{this.props.label}</div>
      </Wrapper>
    );
  }
}

Loading.propTypes = {
  theme: PropTypes.oneOf(['dark', 'light']),
  label: PropTypes.string
};

Loading.defaultProps = {
  theme: 'light',
  label: 'Loading...'
};

export default Loading;
