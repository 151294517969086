import React, { Component } from 'react';

const Group = ({ children, className, onChange, name }) => {
  const newChildren = React.Children.map(children, child => {
    return React.cloneElement(child, {
      name: name,
      onChange: (e, name) => {
        onChange(e, name);
      },
    });
  });
  return <div className="form-radio-button-group d-flex">{newChildren}</div>;
};

class Radio extends Component {
  static Group = Group;

  render() {
    let newChildren = React.cloneElement(this.props.children, {
      onChange: (e, name) => {
        this.props.onChange(e, name);
      },
    });
    const { children, ...props } = this.props;
    return <div {...props}>{newChildren}</div>;
  }
}

// Input.propTypes = {
//   children: PropTypes.arrayOf(
//     PropTypes.shape({
//       type: PropTypes.oneOf([Row])
//     })
//   )
// };

export default Radio;
