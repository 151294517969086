import React from 'react';
import PropTypes from 'prop-types';

const View = ({ url, title }) => {
  return (
    <a
      className="p-1 pr-2"
      title={title}
      target="_blank"
      href={url}
      rel="noreferrer"
    >
      <i className="ti ti-eye" title="View"></i>
    </a>
  );
};

View.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
};

View.defaultProps = {
  title: 'View',
};

export default View;
