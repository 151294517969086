import React, { Component, createContext } from 'react';
import PropTypes from 'prop-types';
import './Form.scss';
import { Util, Button } from '@tuva-ui/components';

const FormContext = createContext({});
const FormProvider = FormContext.Provider;
const FormConsumer = FormContext.Consumer;
const defaultErrorMessage = {
  required: function () {
    return 'Please fill values';
  },
  minLength: function (length) {
    return `Minimum ${length} chars`;
  },
  maxLength: function (length) {
    return `Maximum ${length} chars`;
  },
  pattern: function (length) {
    return `Special characters not support`;
  },
};

class Item extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    let {
      label,
      children,
      onChange,
      name,
      defaultValue,
      labelCol,
      inputCol,
      isVertical,
      values,
      placeholder,
      checkboxValues,
    } = this.props;
    return (
      <FormConsumer>
        {({ data, errors }) => {
          const newChildren = React.cloneElement(children, {
            name,
            defaultValue,
            values,
            placeholder,
            value: data ? data[name] : undefined,
            onChange: (e, name) => {
              onChange(e, name);
            },
            checkboxValues: checkboxValues ? checkboxValues : [],
          });

          let isValidField = !(
            errors[name] &&
            errors[name].isFocusedOnce &&
            !errors[name].isValid
          );

          return (
            <div
              className={`form-group d-flex mt-1 col-12 position-relative${
                !isValidField ? ' form-item-has-error' : ''
              }${isVertical ? '' : ' flex-items-center'}`}
            >
              <label
                className={`form-item-label text-normal p2 ${
                  isVertical ? '' : labelCol ? labelCol : 'col-3'
                }`}
              >
                {label}
              </label>
              <div
                className={`form-item-input-content ${
                  isVertical ? '' : inputCol ? inputCol : 'col-9'
                }`}
              >
                {newChildren}
                {!isValidField && (
                  <div className="form-item-explain-error position-absolute">
                    {errors[name].errorText}
                  </div>
                )}
              </div>
            </div>
          );
        }}
      </FormConsumer>
    );
  }
}

class Form extends Component {
  constructor(props) {
    super(props);
  }
  static Item = Item;
  state = {
    data: {}, // formdata
    errors: {}, // formerrror
  };

  static LAYOUT = {
    VERTICAL: 'tuva-vertical-layout',
    HORIZONTAL: 'tuva-horizontal-layout', // default
  };

  static TYPE = {
    SIMPLE: 'simple',
  };

  onCheckChildren = children => {
    if (typeof children === 'object' && !Array.isArray(children)) {
      // convert object to array if form has only one element
      children = [children];
    }

    // sometimes children are false || null
    children = children.filter(child => child);
    return children;
  };

  onSubmit = e => {
    e.preventDefault();
    let { data } = this.state;
    if (this.props.formData) {
      let formData = new FormData();

      for (let key in data) {
        formData.append(key, data[key]);
      }
      this.props.onSubmit(formData);
    } else {
      this.props.onSubmit(data);
    }

    if (this.props.isReset) {
      document.getElementById(this.props.id).reset();
    }
  };

  checkFieldValidation = (name, value) => {
    let { errors } = this.state;
    let fieldErrorInfo = { isValid: true, errorText: null };
    if (errors[name]) {
      let rules = errors[name].rules;
      if (rules) {
        for (let i = 0; i < rules.length; i++) {
          let errorText = rules[i].message;
          if (rules[i].required) {
            if (value.length == 0 || value == '') {
              return {
                isValid: false,
                errorText: errorText
                  ? errorText
                  : defaultErrorMessage.required(),
              };
            }
          } else if (rules[i].minLength) {
            if (value.length < rules[i].minLength) {
              return {
                isValid: false,
                errorText: errorText
                  ? errorText
                  : defaultErrorMessage.minLength(rules[i].minLength),
              };
            }
          } else if (rules[i].maxLength) {
            if (value.length > rules[i].maxLength) {
              return {
                isValid: false,
                errorText: errorText
                  ? errorText
                  : defaultErrorMessage.maxLength(rules[i].maxLength),
              };
            }
          } else if (rules[i].pattern && rules[i].pattern.source) {
            if (!rules[i].pattern.test(value)) {
              return {
                isValid: !value && errors[name].optional ? true : false,
                errorText: errorText
                  ? errorText
                  : defaultErrorMessage.pattern(),
              };
            }
          }
        }
      }
    }
    return fieldErrorInfo;
  };

  onChange = (e, name) => {
    let value, property_name;
    let { data, errors } = this.state;

    if (name) {
      // only for search input
      if (data[name] == e) {
        return;
      }
      value = e || '';
      property_name = name;
    } else if (e.target?.type === 'checkbox') {
      property_name = e.target.getAttribute('name');
      if (!data[property_name]) {
        data[property_name] = [];
      }
      value = e.target.value.split(',');
    } else {
      // for all other input elements
      value = e.target.value;
      property_name = e.target.getAttribute('name');
    }
    let dataObj = {
      ...data,
      ...{ [property_name]: value },
    };
    if (errors[property_name]) {
      // incase if it is required field
      let checkFieldValidation = this.checkFieldValidation(
        property_name,
        value,
      );
      let errorObj = {
        ...errors,
        ...{
          [property_name]: {
            isValid: checkFieldValidation.isValid,
            errorText: checkFieldValidation.errorText,
            isFocusedOnce: true,
            rules: errors[property_name].rules,
            optional: errors[property_name].optional,
          },
        },
      };
      this.setState({
        errors: errorObj,
        data: dataObj,
      });
    } else {
      this.setState({
        data: dataObj,
      });
    }
  };

  isValid(child) {
    function valueCheck(value) {
      return Number.isFinite(value) || value?.length > 0;
    }
    return valueCheck(child.defaultValue) ||
      child.optional === true ||
      child.values
      ? true
      : false;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let { children, isDynamic } = this.props;
    let { data, errors } = this.state;

    if (!prevProps || !isDynamic) {
      return;
    }

    children = this.onCheckChildren(children);
    for (let i = 0; i < children.length; i++) {
      let child = children[i].props;
      let isNewField = true;
      if (!child.name) {
        continue;
      }
      let prevChildren = prevProps.children.filter(child => child);

      for (let j = 0; j < prevChildren.length; j++) {
        let prevChild = prevChildren[j].props;
        if (!prevChild.name) {
          continue;
        }
        if (
          child.name === prevChild?.name &&
          child.defaultValue !== prevChild?.defaultValue
        )
          //if default value updated in parent component
          this.setState(prevState => ({
            data: {
              ...prevState.data,
              ...{ [prevChild.name]: child.defaultValue },
            },
            errors: {
              ...prevState.errors,
              ...{
                [child.name]: {
                  isValid:
                    (child.defaultValue && child.defaultValue?.length !== 0) ||
                    child.optional === true ||
                    child.values
                      ? true
                      : false,
                  isFocusedOnce: false,
                  rules: child.rules,
                  optional: child.optional,
                },
              },
            },
          }));
        if (child.name === prevChild?.name) {
          isNewField = false;
        }
      }
      if (isNewField) {
        if (child.rules) {
          this.setState(prevState => ({
            data: {
              ...prevState.data,
              ...{ [child.name]: child.defaultValue },
            },
            errors: {
              ...prevState.errors,
              ...{
                [child.name]: {
                  isValid: this.isValid(child),
                  isFocusedOnce: false,
                  rules: child.rules,
                  optional: child.optional,
                },
              },
            },
          }));
        }
      }
    }
  }

  componentDidMount = () => {
    let { children } = this.props;
    let { errors } = this.state;
    let errorData = errors;
    let formData = {};

    children = this.onCheckChildren(children);

    for (let i = 0; i < children.length; i++) {
      let child = children[i].props;
      if (!child.name) {
        continue;
      }
      // storing value to form data if initial value given
      formData = {
        ...formData,
        [child.name]: child.defaultValue ? child.defaultValue : '',
      };

      if (child.rules) {
        // storing all form key's names to create initial error message
        errorData = {
          ...errorData,
          ...{
            [child.name]: {
              isValid:
                child.defaultValue || child.optional === true || child.values
                  ? true
                  : false,
              isFocusedOnce: false,
              rules: child.rules,
              optional: child.optional,
            },
          },
        };
      }
    }
    this.setState({
      errors: errorData,
      data: formData,
    });
  };

  isValidForm = () => {
    let { errors } = this.state;
    let isValid = true;
    Object.entries(errors).map(([key, value]) => {
      if (errors[key].isValid == false) {
        isValid = false;
      }
      return true;
    });
    return !isValid;
  };

  render() {
    const {
      id,
      children,
      cancelText,
      submitText,
      onCancel,
      labelCol,
      inputCol,
      layout,
      type,
      commonError,
      disabled,
      verticalExtraPadding,
      buttonLarge,
    } = this.props;
    let isVertical = false;
    let isSimple = false;
    if (layout == Form.LAYOUT.VERTICAL) {
      isVertical = true;
    }
    if (type == Form.TYPE.SIMPLE) {
      isSimple = true;
    }
    let newChildren = React.Children.map(children, child => {
      if (child) {
        return React.cloneElement(child, {
          labelCol,
          inputCol,
          isVertical,
          checkboxValues: this.state.data[child.props.name]
            ? this.state.data[child.props.name]
            : child.props.defaultValue,
          onChange: (e, name) => {
            this.onChange(e, name);
          },
        });
      }
    });

    return (
      <FormProvider
        value={{
          data: this.state.data,
          errors: this.state.errors,
        }}
      >
        <form
          id={id ? id : Util.getRandomString() + '-form'}
          onSubmit={e => this.onSubmit(e)}
          className={`tuva-form-control ${layout} ${
            isVertical && !verticalExtraPadding ? 'px-3 mx-2 py-1' : ''
          }`}
        >
          <div>{newChildren}</div>

          {/* Form Footer */}
          <div className="form-footer d-flex col-12 mt-5 mb-3">
            {!isSimple ? (
              <>
                {!isVertical && (
                  <div className={`${labelCol ? labelCol : 'col-3'}`}></div>
                )}
                <div
                  className={`${
                    isVertical ? '' : inputCol ? inputCol : 'col-9'
                  }`}
                >
                  <Button
                    className={`bg-white border btn text-gray mr-5 ${
                      buttonLarge ? 'px-5 py-3' : 'px-4 py-2'
                    }`}
                    onClick={e => onCancel(e)}
                    type="button"
                  >
                    {cancelText || 'Cancel'}
                  </Button>
                  <Button
                    className={`btn btn-primary ml-2 mr-3  ${
                      buttonLarge ? 'px-5 py-3' : 'px-4 py-2'
                    }`}
                    style={{ border: '1px solid transparent' }}
                    disabled={this.isValidForm() || disabled}
                    onClick={e => this.onSubmit(e)}
                    type="submit"
                    id={`${id}-submit`}
                  >
                    {submitText ? submitText : 'Submit'}
                  </Button>
                </div>
              </>
            ) : (
              <>
                {!isVertical && (
                  <div className={`${labelCol ? labelCol : 'col-md-3'}`}></div>
                )}
                <button
                  className="btn btn-primary px-5 py-3 mr-3"
                  disabled={this.isValidForm() || disabled}
                  type="submit"
                  id={`${id}-submit`}
                >
                  {submitText ? submitText : 'Submit'}
                </button>
              </>
            )}
          </div>
          <div className="d-flex col-12">
            {!isVertical && (
              <div className={`${labelCol ? labelCol : 'col-md-3'}`}></div>
            )}
            {commonError && (
              <div className="form-error common-error">
                <p>{commonError}</p>
              </div>
            )}
          </div>
        </form>
      </FormProvider>
    );
  }
}

Form.propTypes = {
  id: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  cancelText: PropTypes.string,
  submitText: PropTypes.string,
  onCancel: PropTypes.func,
  labelCol: PropTypes.string,
  inputCol: PropTypes.string,
  layout: PropTypes.string,
  type: PropTypes.string,
  commonError: PropTypes.string,
  verticalExtraPadding: PropTypes.bool,
  buttonLarge: PropTypes.bool,
};

export default Form;
