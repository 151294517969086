import React from 'react';
import PropTypes from 'prop-types';

export const STORAGE_TYPE = { GDRIVE: 'Google', ONEDRIVE: 'One' };

const From = ({ url, storageType, className }) => {
  return storageType ? (
    <a
      className={className}
      title={`${storageType} Drive Spreadsheet`}
      href={url}
      target="_blank"
      rel="noreferrer"
    >
      <i className={`ti ti-${storageType.toLowerCase()}-drive`}></i>
    </a>
  ) : (
    <i className="ti ti-computer text-gray p1"></i>
  );
};

From.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string,
  storageType: PropTypes.string,
};

From.defaultProps = {
  className: '',
  storageType: '',
};

export default From;
