import React from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import './style.scss';

class ToolTip extends React.Component {
  /* Rebuild tooltip */
  static rebuild() {
    ReactTooltip.rebuild();
  }

  render() {
    return (
      <ReactTooltip
        id={this.props.id}
        effect="solid"
        multiline={true}
        place={this.props.place}
        backgroundColor={'#ece01b'}
        textColor={'#000'}
        className={`tuva-tooltip ${
          this.props.className ? this.props.className : ''
        }`}
        clickable={true}
        html={true}
      />
    );
  }
}

ToolTip.propTypes = {
  place: PropTypes.string,
  id: PropTypes.string,
};

ToolTip.defaultProps = {
  place: undefined,
};

export default ToolTip;
