import React from 'react';
import PropTypes from 'prop-types';

const TuvaDataset = ({ url }) => {
  return (
    <a
      title="Tuva Dataset"
      href={url}
      target="_blank"
      rel="noreferrer"
      className="text-purple"
    >
      [Tuva Dataset]
    </a>
  );
};

TuvaDataset.propTypes = {
  url: PropTypes.string,
};

export default TuvaDataset;
