import React, { Component, useState } from 'react';
import { MarkdownInline, Button } from '@tuva-ui/components/';
import Vocabulary from './Vocabulary';

function FooterItem({ text, isMarkdown, isLink, onClick }) {
  return (
    <button
      className={
        'p-2 bg-transparent border-0' + (isLink ? ' link-regular-9' : '')
      }
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      {isMarkdown ? (
        <span>
          <MarkdownInline value={text} />
        </span>
      ) : (
        <span>{text}</span>
      )}
    </button>
  );
}

function Tutorial() {
  return (
    <a
      className="p-2 link-regular-9"
      target="_blank"
      href="https://tuvalabs.com/tutorials/"
      rel="noreferrer"
    >
      <span translate={'true'}>Tutorials</span>
    </a>
  );
}

function VocabularyLink({ keywords }) {
  const [status, setStatus] = useState(false);
  return (
    <>
      <FooterItem
        isLink={true}
        text="Vocabulary"
        onClick={e => setStatus(true)}
      />
      <Vocabulary
        datasetVocabulary={keywords}
        show={status}
        onClose={e => setStatus(false)}
      />
    </>
  );
}

function Title({ text }) {
  return (
    <span
      className="css-truncate css-truncate-target px-2"
      style={{ maxWidth: '300px' }}
    >
      <MarkdownInline value={text} />
    </span>
  );
}

VocabularyLink.displayName = 'Vocabulary';

function Body({ children }) {
  return (
    <div className="overflow-auto" style={{ height: 'calc(100% - 50px)' }}>
      {children}
    </div>
  );
}

class Footer extends Component {
  static FooterItem = FooterItem;
  static Tutorial = Tutorial;
  static Vocabulary = VocabularyLink;
  static Title = Title;
  state = {
    showVocabulary: false,
  };

  render() {
    let { children } = this.props;

    return (
      <div
        style={{ height: 50 }}
        className="border-top border-gray-300 px-3 py-2 overflow-hidden d-flex flex-items-center common-footer"
      >
        {children}
      </div>
    );
  }
}

class StepLeftView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showVocabulary: false,
    };
  }
  static Body = Body;
  static Footer = Footer;
  render() {
    //   const location = useLocation();
    //   const isListViewLanding =
    //     location.pathname.replace(/\//g, '') === 'activities';
    const {
      className,
      dataset,
      user,
      toggleDatasetBookmark,
      isRestricted,
      onRestrictedAccess,
      clickedIconKey,
      setClickedIconKey,
      text,
      about,
      children,
      ..._props
    } = this.props;
    //   const [showAbout, setShowAbout] = useState(isListViewLanding);
    //   const [showVocabulary, setShowVocabulary] = useState(false);

    //   // Handle the icon clicks from content panel
    //   useEffect(() => {
    //     switch (clickedIconKey) {
    //       case 'ti-about-dataset':
    //         setShowAbout(true);
    //         break;
    //       case 'ti-keywords':
    //         setShowVocabulary(true);
    //         break;
    //       default:
    //         break;
    //     }
    //   }, [clickedIconKey]);
    // <Vocabulary datasetVocabulary={datasetVocabulary} show={} onClose={onClose} />;

    return children;
  }
}

export default StepLeftView;
