import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  TuvaToast,
  GoogleService,
  Button,
  Loading,
  AppConfAndUserContext,
} from '@tuva-ui/components';
import { getGoogleGrantAccessText } from './Util';

function AuthenticateGoogle({ onCancel, assignment_type, type, onSubmit }) {
  const { appConf } = React.useContext(AppConfAndUserContext);
  const [inProgress, setInProgress] = useState(false);
  let app_name = appConf?.isDoctrina ? appConf.config.app_label : 'Tuva';

  const shareToGoogle = function () {
    setInProgress(true);
    GoogleService.authenticateForClassroom()
      .then(_hasRefreshToken => {
        if (!_hasRefreshToken) {
          onCancel();
        } else {
          onSubmit();
        }
      })
      .catch(err => {
        setInProgress(false);
        TuvaToast.error('Unable to access your Google Classroom.');
      });
  };

  return inProgress ? (
    <div className="py-6">
      <Loading show label="Connecting to Google classroom..." />
    </div>
  ) : (
    <div className="m-4 text-center">
      <p>{getGoogleGrantAccessText(type, assignment_type, app_name)}</p>
      <div className="pt-4">
        <Button
          className="btn btn-primary btn-md mr-4"
          disabled={inProgress}
          onClick={e => shareToGoogle()}
        >
          Grant Access{' '}
        </Button>

        <Button
          className="btn btn-secondary btn-md"
          disabled={inProgress}
          onClick={e => onCancel()}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
}

AuthenticateGoogle.propTypes = {
  onSubmit: PropTypes.func,
  type: PropTypes.string,
  assignment_type: PropTypes.string,
  onCancel: PropTypes.func,
};

AuthenticateGoogle.defaultProps = {
  assignment_type: '',
};

export default AuthenticateGoogle;
