import React from 'react';
import PropTypes from 'prop-types';

function PremiumLabel({ isLocked }) {
  return (
    <span
      className="d-flex label label--premium py-1 px-2 p2 ml-3"
      data-tip={'Accessible for Tuva Premium Subscribers Only'}
    >
      {isLocked && (
        <i
          className={`ti p3 text-white lh-condensed-ultra mr-2 ${
            isLocked ? 'ti-lock' : ''
          }`}
        ></i>
      )}
      Premium
    </span>
  );
}

PremiumLabel.propTypes = {
  isLocked: PropTypes.bool,
};

PremiumLabel.defaultProps = {
  isLocked: false,
};

export default PremiumLabel;
