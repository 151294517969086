import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { BasicModal, Loading, AnchorWithoutLink } from '@tuva-ui/components/';
import DatasetNoteHistoryAPI from '../../DatasetNoteHistoryService';
import JSONcompareModel from './JSONcompareModel';

const MetadataModel = ({ datasetId, type, appTypeBaseUrl, onClose }) => {
  const [metadatas, setMetadatas] = useState(null);
  const [compareMetadata, setCompareMetadata] = useState(null);
  const [showJsonCompareModal, setShowJsonCompareModal] = useState(false);
  const [datasetName, setDatasetName] = useState('');

  const fetchMetadata = useCallback(() => {
    DatasetNoteHistoryAPI.getMetadata(datasetId, type, appTypeBaseUrl)
      .then(function (data) {
        setMetadatas(data.metadata_history);
      })
      .catch(function (data) {
        console.log('Unable to get metadata', data);
      });
  }, [datasetId, type, appTypeBaseUrl]);

  useEffect(() => {
    fetchMetadata();
  }, [fetchMetadata]);

  useEffect(() => {
    if (metadatas && metadatas.length > 0) {
      setDatasetName(metadatas[0].dataset_name);
    }
  }, [metadatas]);

  return (
    <BasicModal
      isOpen={true}
      className="popup popup-large"
      onClose={e => onClose()}
    >
      <div className="popup-header border-bottom py-3 px-4 border-gray-300">
       <h5>
         Dataset Metadata{' '}
         <span style={{ fontWeight: 'normal' }}> - {datasetName}</span>
       </h5>
     </div>

      <div
        className="py-3 px-4"
        style={{ minWidth: '40%', minHeight: '500px' }}
      >
        {metadatas ? (
          metadatas.length === 0 ? (
            <div className="py-6 text-center">No Results Found.</div>
          ) : (
            metadatas.map((metadata, index) => {
              return (
                <div
                  key={index}
                  className="clearfix py-2 border-bottom border-gray-300"
                >
                  <div className="float-left col-4 d-flex">
                    {metadata.previous_metadata && (
                      <AnchorWithoutLink
                        onClick={e => {
                          setCompareMetadata(metadata);
                          setShowJsonCompareModal(true);
                        }}
                        className={`p3 `}
                        type="button"
                      >
                        Compare Metadata
                      </AnchorWithoutLink>
                    )}
                  </div>
                  <div className="float-left col-4 d-flex">
                    {metadata.date}
                  </div>
                  <div className="float-left col-4 css-truncate css-truncate-target">
                    {metadata?.added_by?.username
                      ? metadata.added_by?.username
                      : ''}
                  </div>
                </div>
              );
            })
          )
        ) : (
          <div className="py-6">
            <Loading show label="Loading Metadata ..." />
          </div>
        )}
      </div>
      {showJsonCompareModal && (
        <JSONcompareModel
          oldMetadata={compareMetadata.previous_metadata}
          newMetadata={compareMetadata.current_metadata}
          datasetName={compareMetadata.dataset_name}
          date={compareMetadata.date}
          onClose={() => setShowJsonCompareModal(false)}
        />
      )}
    </BasicModal>
  );
};

MetadataModel.propTypes = {
  datasetId: PropTypes.number,
  type: PropTypes.string,
  appTypeBaseUrl: PropTypes.string,
  onClose: PropTypes.func,
};

export default MetadataModel;
