import React, { Component, useEffect, useState } from 'react';
import {
  MarkdownInline,
  Loading,
  StepLocalStorageUtil,
  StepHeaderRibbon,
  TextSizePickerButton,
  Button,
  FeedBackModel,
  StepHeaderCollapsibleWrapper,
} from '@tuva-ui/components/';
import StepDropDown from './StepDropDown';
import MarkdownWrapper from './MarkdownWrapper';
import './step.scss';

const Footer = ({
  nextBtnDisabled,
  showPreviousBtn,
  previousBtnText,
  showNextBtn,
  nextBtnText,
  goPrevious,
  goNext,
  disableNavigationButtons,
  feedback,
}) => {
  let [feedbackModelStatus, setFeedbackModelStatus] = useState(false);
  return (
    <div
      className="border-top border-gray-300 common-footer px-3 py-2 d-flex"
      style={{ height: 50 }}
    >
      <>
        <div className="d-flex flex-auto flex-self-center feedback-anchor">
          {feedback && (
            <>
              <Button
                className="link-gray-regular-9 p-0 border-0 bg-none"
                onClick={e => {
                  if (feedback.user) {
                    setFeedbackModelStatus(true);
                  } else {
                    window.revealModal('logIn');
                  }
                }}
              >
                <i className="f4 fa fa-exclamation-circle"></i>{' '}
                <span>Feedback</span>
              </Button>
              <FeedBackModel
                show={feedbackModelStatus}
                module={feedback.module}
                title={feedback.title}
                user={feedback.user}
                leftContent={feedback.leftContent}
                content={feedback.content}
                onClose={e => setFeedbackModelStatus(false)}
              />
            </>
          )}
        </div>
        {showPreviousBtn && (
          <Button
            type="button"
            disabled={disableNavigationButtons}
            className="btn btn-secondary mr-2"
            onClick={e => goPrevious()}
            closesidebar="true"
          >
            {previousBtnText ? previousBtnText : 'Previous'}
          </Button>
        )}
        {showNextBtn && (
          <Button
            type="button"
            disabled={disableNavigationButtons || nextBtnDisabled}
            className={`btn ${
              nextBtnText === 'Check' ? 'btn-gold' : 'btn-primary'
            }`}
            closesidebar="true"
            onClick={e => goNext(true)}
          >
            {nextBtnText}
          </Button>
        )}
      </>
    </div>
  );
};
Footer.displayName = 'Footer';

const Header = ({
  stepDropdown,
  title,
  ribbon,
  textSize,
  children,
  childrenDirection = 'column', // 'row'=> display children in a row along with title, default all children display in a column
  isTuvaJR,
  enrollBtnHandler,
  showEnrollBtn,
}) => {
  const [showTextSizePicker, setShowTextSizePicker] = useState(
    StepLocalStorageUtil.getShowTextSizePicker(),
  );

  useEffect(() => {
    StepLocalStorageUtil.setShowTextSizePicker(showTextSizePicker);
  }, [showTextSizePicker]);

  return (
    <>
      <div className="bg-green-0 px-3 py-2">
        <div className="text-green d-flex flex-items-center">
          <div
            className={
              'flex-1' + (childrenDirection === 'row' ? ' d-flex' : '')
            }
          >
            <h4 className="p1 text-green d-flex flex-items-center">
              {/* {title.showIcon && (
                <span
                  className={`ti  ${title.showIcon} mr-2 d-inline-flex`}
                ></span>
              )} */}
              <span className="ellipsis-line-clamp" style={{ height: 'auto' }}>
                <MarkdownInline value={title.title} />
              </span>
            </h4>
            {children}
          </div>
          {title.editUrl && (
            <a href={title.editUrl} target="_blank" rel="noreferrer">
              <i className="pl-2 ti ti-pencil" />
            </a>
          )}
          {showEnrollBtn ? (
            <div className="d-flex flex-items-start">
              <Button
                type="button"
                className={`ml-2 btn btn-primary`}
                closesidebar="true"
                onClick={e => enrollBtnHandler(true)}
              >
                Enroll
              </Button>
              <TextSizePickerButton
                showTextSizePicker={showTextSizePicker}
                toggleShowTextSizePicker={() =>
                  setShowTextSizePicker(!showTextSizePicker)
                }
              />
            </div>
          ) : (
            <TextSizePickerButton
              showTextSizePicker={showTextSizePicker}
              toggleShowTextSizePicker={() =>
                setShowTextSizePicker(!showTextSizePicker)
              }
            />
          )}
        </div>

        {stepDropdown && <StepDropDown {...stepDropdown}></StepDropDown>}
      </div>
      {ribbon && <StepHeaderRibbon {...ribbon} />}

      <StepHeaderCollapsibleWrapper
        showTextSizePicker={showTextSizePicker}
        isTuvaJR={isTuvaJR}
        {...textSize}
      />
    </>
  );
};

Header.displayName = 'Header';

const Body = React.forwardRef(
  (
    {
      content,
      questions,
      showLoading,
      answer_key,
      onSimulation,
      getPlotState,
      getPlotPreview,
      textSizeClass,
      checkIsFormDisabled,
      userAnswers,
      onMarkdownIconClick,
      contentHeader,
    },
    ref,
  ) => {
    return (
      <div className={'p-3 flex-1 overflow-auto' + textSizeClass}>
        {showLoading && (
          <div className="mt-6 pt-6">
            <Loading label="Loading step..."></Loading>
          </div>
        )}
        {!showLoading && (
          <>
            {contentHeader && contentHeader}
            <MarkdownWrapper
              checkIsFormDisabled={checkIsFormDisabled}
              ref={ref}
              userAnswers={userAnswers}
              content={content}
              questions={questions}
              answerKey={answer_key}
              getPlotState={getPlotState}
              getPlotPreview={getPlotPreview}
              onSimulation={onSimulation}
              onIconClick={onMarkdownIconClick}
            ></MarkdownWrapper>
          </>
        )}
      </div>
    );
  },
);

const SimpleBody = ({ textSizeClass, children, appendClass, customClass }) => {
  let cssClass = 'p-3 flex-1 overflow-auto';
  if (customClass) {
    cssClass = customClass;
  }
  if (appendClass) {
    cssClass += ' ' + appendClass;
  }

  return (
    <div className={cssClass + textSizeClass}>
      <>{children}</>
    </div>
  );
};

Body.displayName = 'Body';
SimpleBody.displayName = 'SimpleBody';

const FooterButtons = {
  next: 'Next',
  check: 'Check',
  submit: 'Submit',
  finish: 'Finish',
  enroll: 'Enroll',
};
class Step extends Component {
  state = {
    textSizeClass: '',
  };
  static Header = Header;
  static Body = Body;
  static SimpleBody = SimpleBody;
  static Footer = Footer;
  static FooterButtons = FooterButtons;

  setTextSizeClass = type => {
    this.setState({ textSizeClass: type });
  };

  render() {
    const { children } = this.props;

    let newChildren = React.Children.map(children, child => {
      if (child.type.displayName === 'Header') {
        return React.cloneElement(child, {
          textSize: {
            setTextSizeClass: this.setTextSizeClass,
            isTuvaJR: this.props.isTuvaJR,
          },
        });
      } else if (child.type.displayName.includes('Body', 'SimpleBody')) {
        return React.cloneElement(child, {
          textSizeClass: this.state.textSizeClass,
          isTuvaJR: this.props.isTuvaJR,
        });
      } else {
        return child;
      }
    });

    return <>{newChildren}</>;
  }
}

export default Step;
