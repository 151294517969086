import axios from 'axios';

class DatasetNoteHistoryService {
  getNotes(datasetId, type, appTypeBaseUrl = 'insert-instance-and-datasets') {
    return axios
      .get(`/api/${appTypeBaseUrl}/notes/${datasetId}/?type=${type}`)

      .then(response => {
        return response.data;
      })
      .catch(err => {
        return;
      });
  }

  getHistory(datasetId, type, appTypeBaseUrl = 'insert-instance-and-datasets') {
    return axios
      .get(`/api/${appTypeBaseUrl}/history/${datasetId}/?type=${type}`)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        return;
      });
  }

  getMetadata(datasetId, type, appTypeBaseUrl) {
    return axios
      .get(`/api/${appTypeBaseUrl}/metadata-history/${datasetId}/?type=${type}`)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        return;
      });
  }
}

const DatasetNoteHistoryAPI = new DatasetNoteHistoryService();

export default DatasetNoteHistoryAPI;
