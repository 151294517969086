import React, { Component } from 'react';
import './select.scss';
import PropTypes from 'prop-types';

class Select extends Component {
  constructor(props) {
    super(props);

    this.state = {
      values: props.values
        ? props.multiple
          ? props.values
          : [props.values]
        : [],
      focusedValue: -1,
      isFocused: false,
      isSearchFocused: false,
      isOpen: false,
      typed: '',
    };
  }

  onFocus = () => {
    this.setState({
      isFocused: true,
    });
  };

  onBlur = () => {
    const { multiple } = this.props;
    const { isSearchFocused } = this.state;
    if (isSearchFocused) {
      // avoid blur option if search box is focus
      return;
    }
    this.setState(
      prevState => {
        const { values } = prevState;

        if (multiple) {
          return {
            focusedValue: -1,
            isFocused: false,
            isOpen: false,
          };
        } else {
          const value = values[0];

          let focusedValue = -1;

          if (value) {
            focusedValue = this.getIndex(value);
          }

          return {
            focusedValue,
            isFocused: false,
            isOpen: false,
          };
        }
      },
      () => this.onChangeEvent(),
    );
  };

  getIndex = key => {
    const { multiple, valueKey, displayKey } = this.props;
    const { values } = this.state;
    let index;
    if (displayKey && multiple) {
      index = values.findIndex(o => o[valueKey] === key[valueKey]);
    } else {
      index = values.indexOf(key);
    }
    return index;
  };

  onValueSelect = (selectedOption = false) => {
    const { multiple } = this.props;

    this.setState(
      prevState => {
        const [...values] = prevState.values;
        let key = selectedOption;
        if (!multiple) {
          return {
            values: [key],
            isOpen: false,
          };
        }
        let index = this.getIndex(key);
        if (index === -1) {
          values.push(key);
        } else {
          values.splice(index, 1);
        }
        return { values };
      },
      () => this.onChangeEvent(),
    );
  };

  onKeyDown = e => {
    const { options, multiple, valueKey, displayKey } = this.props;
    const { isOpen, isSearchFocused } = this.state;

    switch (e.key) {
      case ' ':
        // to check or uncheck from the list
        if (!isSearchFocused) {
          e.preventDefault();
          if (isOpen) {
            if (multiple) {
              this.setState(prevState => {
                const { focusedValue } = prevState;
                if (focusedValue !== -1) {
                  return this.onValueSelect(
                    displayKey
                      ? options[focusedValue][valueKey]
                      : options[focusedValue],
                  );
                }
              });
            }
          } else {
            this.setState({
              isOpen: true,
            });
          }
        }
        break;
      case 'Escape':
      case 'Tab':
        if (isOpen) {
          e.preventDefault();
          this.setState({
            isOpen: false,
          });
        }
        break;
      case 'Enter':
        this.setState(
          prevState => ({
            isOpen: !prevState.isOpen,
          }),
          () => this.onChangeEvent(),
        );
        break;
      case 'ArrowDown':
        e.preventDefault();
        this.setState(prevState => {
          let { focusedValue } = prevState;

          if (focusedValue < options.length - 1) {
            focusedValue++;

            if (multiple) {
              return {
                focusedValue,
              };
            } else {
              return {
                values: displayKey
                  ? [options[focusedValue][valueKey]]
                  : [options[focusedValue]],
                focusedValue,
              };
            }
          }
        });
        break;
      case 'ArrowUp':
        e.preventDefault();
        this.setState(prevState => {
          let { focusedValue } = prevState;

          if (focusedValue > 0) {
            focusedValue--;

            if (multiple) {
              return {
                focusedValue,
              };
            } else {
              return {
                values: displayKey
                  ? [options[focusedValue][valueKey]]
                  : [options[focusedValue]],
                focusedValue,
              };
            }
          }
        });
        break;
      default:
        if (/^[a-z0-9]$/i.test(e.key)) {
          const char = e.key;

          clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
            this.setState({
              typed: '',
            });
          }, 1000);

          this.setState(prevState => {
            const typed = prevState.typed + char;
            const re = new RegExp(`^${typed}`, 'i');
            const index = options.findIndex(option =>
              re.test(displayKey ? option[valueKey] : option),
            );
            if (index === -1) {
              return {
                typed,
              };
            }

            if (multiple) {
              return {
                focusedValue: index,
                typed,
              };
            } else {
              return {
                values: displayKey
                  ? [options[index][valueKey]]
                  : [options[index]],
                focusedValue: index,
                typed,
              };
            }
          });
        }
        break;
    }
  };

  onClick = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  };

  onDeleteOption = value => {
    this.setState(
      () => {
        let index;
        const [...values] = this.state.values;
        if (this.state.displayKey) {
          index = values.findIndex(
            o => o[this.state.valueKey] === value[this.state.valueKey],
          );
        } else {
          index = values.indexOf(value);
        }
        values.splice(index, 1);
        return { values };
      },
      () => this.onChangeEvent(),
    );
  };

  onHoverOption = key => {
    this.setState({
      focusedValue: this.getOptionsIndex(key),
    });
  };

  getOptionsIndex = key => {
    let { displayKey, multiple, valueKey, options } = this.props;
    let index;
    if (displayKey && multiple) {
      index = options.findIndex(o => o[valueKey] === key[valueKey]);
    } else if (displayKey) {
      index = options.findIndex(o => o[valueKey] === key);
    } else {
      index = options.indexOf(key);
    }
    return index;
  };

  onClickOption = option => {
    return this.onValueSelect(option);
  };

  stopPropagation = e => {
    e.stopPropagation();
  };

  searchTextChange = e => {
    this.props.onSearch(e.target.value);
  };

  renderValues = () => {
    const {
      placeholder,
      multiple,
      searchable,
      options,
      autoFocus,
      valueKey,
      displayKey,
    } = this.props;
    const { values, isSearchFocused, isOpen } = this.state;
    if (values.length === 0 && !searchable) {
      return <div className="placeholder">{placeholder}</div>;
    }

    if (multiple) {
      return (
        <div>
          {values.map((value, index) => {
            return (
              <span
                key={'options' + index}
                onClick={this.stopPropagation}
                className="multiple d-inline-block value position-relative"
              >
                {displayKey ? value[displayKey] : value}
                <span
                  onClick={() => this.onDeleteOption(value)}
                  className="d-block position-absolute delete"
                >
                  <X />
                </span>
              </span>
            );
          })}
          {searchable && (
            <input
              id="tuvaSearch"
              className="ml-1 border border-green"
              onClick={this.stopPropagation}
              onChange={this.searchTextChange}
              onFocus={() => {
                this.setState({ isSearchFocused: true, isOpen: true });
              }}
              onBlur={() => {
                if (options.length === 0) {
                  this.setState({ isSearchFocused: false, isOpen: false });
                } else {
                  this.setState({ isSearchFocused: false });
                }
              }}
              autoComplete="off"
              autoFocus={autoFocus}
              placeholder={placeholder ? placeholder : 'Search here'}
            ></input>
          )}
        </div>
      );
    }
    let style = {};
    if (searchable) {
      style = {
        position: 'absolute',
        right: 0,
        left: 0,
        top: 0,
        bottom: 0,
        zIndex: -1,
        width: '100%',
        margin: '-4px -30px -4px -4px',
        border: 'none',
        outline: 'none',
        padding: 0,
        paddingLeft: '8px',
        paddingBottom: '3px',
        marginBottom: '-10px',
      };
      if (values.length === 0 || isSearchFocused || isOpen) {
        style['zIndex'] = 10;
      }
    }

    return (
      <div
        ref={e => (this.selectBox = e)}
        className="position-relative d-inline-block value text-left no-wrap overflow-hidden text-ellipsis"
        style={{ width: '100%', minHeight: '25px' }}
      >
        {searchable && (isOpen || values?.length == 0) && (
          <input
            id="tuvaSearch"
            onClick={this.stopPropagation}
            onChange={this.searchTextChange}
            onFocus={() => {
              this.setState({ isSearchFocused: true, isOpen: true });
            }}
            onBlur={() => {
              if (options.length === 0) {
                this.setState({ isSearchFocused: false, isOpen: false });
              } else {
                this.setState({ isSearchFocused: false });
              }
            }}
            defaultValue={values}
            autoComplete="off"
            style={style}
            autoFocus={autoFocus}
            placeholder={placeholder ? placeholder : 'Search here'}
          ></input>
        )}
        {displayKey
          ? options.find(option => option[valueKey] === values[0])[displayKey]
          : values[0]}
      </div>
    );
  };

  renderOptions = dropdownPosition => {
    const { options, loader, searchable } = this.props;
    const { isOpen } = this.state;
    if (!isOpen || (searchable && options.length === 0)) {
      return null;
    }

    if (loader) {
      return (
        <div className="options position-absolute">
          <div style={{ pointerEvents: 'none' }} className="option text-center">
            <span className="btn-loader loading">
              <svg
                className="select-loader--spinner"
                viewBox="0 0 32 32"
                width="18"
                height="18"
              >
                <path
                  fill="#008B94"
                  d="M16 0 A16 16 0 0 0 16 32 A16 16 0 0 0 16 0 M16 4 A12 12 0 0 1 16 28 A12 12 0 0 1 16 4"
                />
                <path
                  fill="#ffffff"
                  d="M16 0 A16 16 0 0 1 32 16 L28 16 A12 12 0 0 0 16 4z"
                ></path>
              </svg>
            </span>
          </div>
        </div>
      );
    }

    if (!options || (options.length === 0 && !searchable)) {
      return (
        <div className="options position-absolute">
          <div style={{ pointerEvents: 'none' }} className="option">
            {' '}
            No data Found
          </div>
        </div>
      );
    }

    return (
      <div
        className={`options position-absolute ${dropdownPosition}`}
        style={{ zIndex: 1000 }}
      >
        {options.map(this.renderOption)}
      </div>
    );
  };

  renderOption = (option, index) => {
    const { multiple, displayKey, valueKey } = this.props;
    const { values, focusedValue } = this.state;

    let label, key;
    if (option instanceof Object) {
      label = option[displayKey];
      key = option[valueKey];
    } else {
      label = option;
      key = option;
    }

    let selected = false;
    if (displayKey && multiple) {
      selected = values.find(v => v[valueKey] === option[valueKey]);
    } else {
      selected = values.includes(key);
    }

    let className = 'option';
    if (selected) className += ' selected';

    if (index === focusedValue) {
      className += ' focused';
    }

    return (
      <div
        key={`select option ${index}`}
        data-key={key}
        data-label={label}
        className={className}
        onMouseOver={() => {
          this.onHoverOption(multiple && displayKey ? option : key);
        }}
        onMouseDown={() =>
          this.onClickOption(multiple && displayKey ? option : key)
        }
      >
        {multiple ? (
          <span className="tuva-checkbox position-relative v-align-top d-inline-block text-green">
            {selected ? <Check /> : null}
          </span>
        ) : null}
        {label}
      </div>
    );
  };

  onChangeEvent = () => {
    const { multiple } = this.props;
    const { values } = this.state;
    if (!(JSON.stringify(this.props.values) === JSON.stringify(values))) {
      if (multiple) {
        this.props.onChange(values);
      } else {
        this.props.onChange(values[0]);
      }
    }
  };

  getDropdownPosition = () => {
    // TODO - get dynamically from select box position,options & window height
    let optionsHeight = 200;
    // TODO - get dynamically from respective component
    let bufferHeight = 70;
    let requiredTopPosition = 'bottom';
    if (this.selectBox) {
      let inputBoxHeight = this.selectBox.offsetHeight;
      requiredTopPosition =
        this.selectBox.getBoundingClientRect().top +
          optionsHeight +
          bufferHeight +
          inputBoxHeight >
        window.innerHeight
          ? 'bottom'
          : 'top';
    }
    return requiredTopPosition;
  };

  render() {
    const { label, style, className, id } = this.props;
    const { isOpen } = this.state;
    let dropdownPosition = this.getDropdownPosition();

    return (
      <div
        className={`tuva-select position-relative d-inline-block ${
          isOpen ? 'focus' : null
        } ${className ? className : null}`}
        tabIndex="0"
        id={id ? id : ''}
        style={style}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onKeyDown={this.onKeyDown}
      >
        {label && <label className="d-block label">{label}</label>}
        <div
          className={`selection position-relative ${dropdownPosition}`}
          onClick={this.onClick}
        >
          {this.renderValues()}
          <span
            className={`arrow position-absolute ${
              isOpen ? 'up-arrow' : undefined
            }`}
          >
            {isOpen ? <ChevronUp /> : <ChevronDown />}
          </span>
        </div>
        {this.renderOptions(dropdownPosition)}
      </div>
    );
  }
}

const ChevronDown = () => (
  <span className="inner">
    <i className="ti ti-triangle-bottom"></i>
  </span>
);

const ChevronUp = () => (
  <span className="inner" style={{ transform: 'rotate(180deg)' }}>
    <i className="ti ti-triangle-bottom"></i>
  </span>
);

const X = () => (
  <span className="inner">
    <i className="ti ti-remove"></i>
  </span>
);

const Check = () => (
  <span className="inner position-absolute" style={{ top: '-1px' }}>
    <i className="ti ti-tick position-absolute"></i>
  </span>
);

Select.propTypes = {
  placeholder: PropTypes.string, // placeholder text
  valueKey: PropTypes.string, // value key, which we will return to onChange
  displayKey: PropTypes.string, // display key, which we will show it on options
  multiple: PropTypes.bool, // if it is multiple select
  searchable: PropTypes.bool, //  search input in select box
  options: PropTypes.any, // list of options
  loader: PropTypes.bool, // search loader
  onChange: PropTypes.func, // onchange cb
  onSearch: PropTypes.func, // onsearchkey change cb
  autoFocus: PropTypes.bool, // default search focus
  values: PropTypes.any, // default || selected values
  className: PropTypes.string, // class name
  style: PropTypes.object, // inline style
};

export default Select;
