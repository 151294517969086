import Name from './Name';
import Refresh from './Refresh';
import TuvaDataset from './TuvaDataset';
import DatasetPrivacy from './DatasetPrivacy';
import Fork from './Fork';
import Metadata from './Metadata';

function Title({ className, children }) {
  return <div className={className}>{children}</div>;
}

Title.Name = Name;
Title.Refresh = Refresh;
Title.Metadata = Metadata;
Title.TuvaDataset = TuvaDataset;
Title.DatasetPrivacy = DatasetPrivacy;
Title.Fork = Fork;

export default Title;
