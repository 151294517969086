import { useState, useRef, useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';
import Util from '../../Util';
import { SidebarSkeleton, Markdown } from '@tuva-ui/components/';
import KeywordPopoverService from './KeywordPopover.service';
const KeywordPopover = props => {
  const KeywordPopoverRef = useRef();
  const PopupContentRef = useRef();
  const keywordRef = useRef();
  const [show, setShow] = useState(false);
  const [description, setDescription] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [arrowClassName, setArrowClassName] = useState(false);
  const [maxHeight, setMaxHeight] = useState(false);
  const [showPosition, setShowPosition] = useState({ x: 'top', y: 'left' });
  Util.useOnClickOutside(KeywordPopoverRef, () => setShow(false));
  Util.useOnScrollOutside(KeywordPopoverRef, () => setShow(false));

  let setPopupPosition = useCallback(() => {
    let keywordPosition = keywordRef.current.getBoundingClientRect();
    let popupContentPosition =
      KeywordPopoverRef.current.getBoundingClientRect();
    // To position the picker based on target element's position and available window height
    let showPositionCss = {};
    let LineHeight = 20;
    let popupContentPositionHeight = popupContentPosition.height + LineHeight;
    if (keywordPosition.y > window.innerHeight / 2 + LineHeight) {
      //Top section has more height, Render the popover to top
      if (keywordPosition.y > popupContentPositionHeight) {
        // Popover height is fit with window
        showPositionCss.top =
          keywordPosition.y - popupContentPositionHeight + 5;
      } else {
        // Popover height not fit, so set max height
        showPositionCss.top = 50;
        setMaxHeight(keywordPosition.top - 110);
      }
      setArrowClassName('popup-arrow-top');
    } else {
      let hasEnoughSpaceOnBottom =
        keywordPosition.bottom > popupContentPositionHeight;
      showPositionCss.top = keywordPosition.bottom + LineHeight;
      if (!hasEnoughSpaceOnBottom) {
        setMaxHeight(window.innerHeight - keywordPosition.bottom - 100);
      } else {
        setMaxHeight('');
      }
      setArrowClassName('popup-arrow-bottom');
    }

    // To position the picker based on target element's position and available window width
    if (keywordPosition.x > window.innerWidth - 320) {
      showPositionCss.right = window.innerWidth - keywordPosition.x - 38;
    } else {
      showPositionCss.left = keywordPosition.x - 13;
    }
    setShowPosition({
      showPositionCss,
    });
  }, []);

  let loadKeywordDescription = useCallback(() => {
    KeywordPopoverService.getDescription(props.keyword_id)
      .then(function (data) {
        setMaxHeight('');
        setDescription(data.data);
        setIsLoading(false);
        setTimeout(() => {
          setPopupPosition();
        }, 10);
      })
      .catch(function (err) {
        console.log('Error while fetching description', err);
      });
  }, [props.keyword_id, setPopupPosition]);

  useEffect(() => {
    if (show) {
      loadKeywordDescription();
    }
  }, [loadKeywordDescription, show]);

  const closeHandler = useCallback(() => {
    setShow(false);
  }, []);

  return (
    <span className="position-relative">
      <span
        ref={keywordRef}
        className="keyword-popover-text cursor-pointer"
        onClick={e => {
          setPopupPosition();
          setShow(true);
        }}
      >
        {props.children}
      </span>
      {createPortal(
        <div
          ref={KeywordPopoverRef}
          style={{
            ...{
              visibility: show ? 'visible' : 'hidden',
              opacity: show ? '1' : '0',
            },
            ...showPosition['showPositionCss'],
          }}
          className={`keyword-popover-overlay border`}
        >
          <div
            ref={PopupContentRef}
            className={`keyword-popup ${
              showPosition['showPositionCss']?.left ? 'left' : 'right'
            }`}
          >
            <div className={arrowClassName}></div>
            <span className={'close'} onClick={closeHandler}>
              &times;
            </span>
            <div
              style={{ maxHeight: maxHeight ? maxHeight : 'max-content' }}
              className={'keyword-content'}
            >
              {isLoading ? (
                <SidebarSkeleton Lines={2} />
              ) : (
                <Markdown value={description} isDisabled={true}></Markdown>
              )}
            </div>
          </div>
        </div>,
        document.body,
      )}
    </span>
  );
};

export default KeywordPopover;
