import React from 'react';
import PropTypes from 'prop-types';
import { BasicModal } from '@tuva-ui/components/';

const NotesModel = ({ notes, title, onClose }) => {
  return (
    <BasicModal
      isOpen={true}
      style={{ minWidth: '40%', minHeight: '500px' }}
      className="popup popup-large"
      onClose={e => onClose()}
    >
      <BasicModal.Header headerText={title} />
      <div className="py-3 px-4">
        {notes.map((note, index) => {
          return note.note ? (
            <div key={index}>
              <p className="mb-0 text-gray-light">{note.created_at}</p>
              <p>
                {note.note}
                <span className="text-gray-light">
                  {note?.added_by?.username
                    ? ' - ' + note.added_by?.username
                    : ''}
                </span>
              </p>
            </div>
          ) : (
            ''
          );
        })}
      </div>
    </BasicModal>
  );
};

NotesModel.propTypes = {
  notes: PropTypes.arrayOf(
    PropTypes.shape({
      created_at: PropTypes.string,
      note: PropTypes.string,
      added_by: PropTypes.object,
    }),
  ),
  title: PropTypes.string,
  onClose: PropTypes.func,
};
NotesModel.defaultProps = {
  title: 'Staff Notes',
};

export default NotesModel;
