function DetectGoogleTranslateChange() {
  // Select the <html> element (root of the document)
  const targetNode = document.documentElement;

  // Configuration of the observer: listen for attribute changes
  const config = { attributes: true, attributeFilter: ['class', 'lang'] };

  let translateClassNameEvent = 'removed';

  // Callback function to execute when mutations are observed
  const callback = mutationsList => {
    let requireRefresh = false;

    for (let mutation of mutationsList) {
      if (mutation.type === 'attributes') {
        // Check for the class translated-ltr or translated-rtl
        const classListContainsTranslated =
          targetNode.className.includes('translated-');

        // Listen for language changes
        if (mutation.attributeName === 'lang' && classListContainsTranslated) {
          requireRefresh = true;
        }

        // Listen for class changes
        if (!requireRefresh && mutation.attributeName === 'class') {
          if (
            translateClassNameEvent === 'removed' &&
            classListContainsTranslated
          ) {
            requireRefresh = true;
            translateClassNameEvent = 'added';
          } else if (
            translateClassNameEvent === 'added' &&
            !classListContainsTranslated
          ) {
            requireRefresh = true;
            translateClassNameEvent = 'removed';
          }
        }
      }
    }

    if (requireRefresh) {
      // Dispatch a resize event to trigger tuva-data-tools to recalculate
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      });
    }
  };

  // Create an instance of MutationObserver and pass the callback
  const observer = new MutationObserver(callback);

  // Start observing the <html> element
  observer.observe(targetNode, config);
}

export default DetectGoogleTranslateChange;
