import React, { Component } from 'react';
import Modal from 'react-modal';
import './style.scss';
import Button from '../Button/Button';

const modalContentStyles = {
  position: 'absolute',
  top: '0',
  left: '0',
  right: '0',
  bottom: '0',
  padding: 0,
  background: 'transparent',
  border: 'none',
};

const modalOverlayStyles = {
  zIndex: 9999,
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
};

const innerContentStyle = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  right: 'auto',
  bottom: 'auto',
  border: '1px solid rgb(204, 204, 204)',
  background: 'rgb(255, 255, 255)',
  overflow: 'auto',
  borderRadius: '4px',
  outline: 'none',
  padding: '20px',
  maxWidth: '750px',
  marginRight: '-50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: 'calc(100vh - 80px)',
};

const SIZES = {
  SMALL: 'popup-small',
  MEDIUM: 'popup-medium',
  LARGE: 'popup-large',
};

class TuvaModal extends Component {
  static SIZES = SIZES;
  render() {
    const contentStyle = Object.assign({}, innerContentStyle, this.props.style);
    const updatedModalOverlayStyles = Object.assign(
      {},
      modalOverlayStyles,
      this.props.modelOverlayStyle,
    );
    return (
      <Modal
        isOpen={this.props.isOpen}
        ariaHideApp={false}
        style={{
          content: modalContentStyles,
          overlay: updatedModalOverlayStyles,
        }}
        onRequestClose={this.props.onClose}
      >
        <div style={modalContentStyles} onClick={this.props.onClose}>
          {this.props.closeIcon && (
            <Button
              className="tuva-modal-close"
              onClick={e => this.props.onClose()}
            ></Button>
          )}
          <div
            className={
              'popup ' +
              (this.props.type || '') +
              ' ' +
              (this.props.className || '')
            }
            style={contentStyle}
            onClick={e => e.stopPropagation()}
          >
            {this.props.children}
          </div>
        </div>
      </Modal>
    );
  }
}

export default TuvaModal;
