import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './SearchBox.scss';

const SearchBox = ({ onSearch, className, bgColor }) => {
  let [searchText, setSearchText] = useState('');
  let [showSearchIcon, setShowSearchIcon] = useState(true);

  return (
    <div
      className={`text-normal mr-3 ${className}`}
      style={{ position: 'relative' }}
    >
      <input
        onKeyDown={e => {
          if (e.key === 'Enter') {
            setShowSearchIcon(false);
            onSearch(searchText);
          }
        }}
        onChange={e => {
          setSearchText(e.target.value);
        }}
        value={searchText}
        className={`form-control pr-5 ${bgColor}`}
        style={{ width: '100%' }}
        placeholder="Search"
      ></input>
      <span
        onClick={e => {
          if (showSearchIcon) {
            onSearch(searchText);
          } else {
            setSearchText('');
            setShowSearchIcon(true);
            onSearch('');
          }
        }}
        className={`cursor-pointer p-1 text-gray ti ${
          showSearchIcon ? 'ti-search' : 'ti-close'
        }`}
        style={{ position: 'absolute', top: '1px', right: '5px' }}
      ></span>
    </div>
  );
};

SearchBox.propTypes = {
  onSearch: PropTypes.func,
  className: PropTypes.string,
  bgColor: PropTypes.string,
};

SearchBox.defaultProps = {
  className: '',
  bgColor: 'bg-white',
};

export default SearchBox;
