import React from 'react';
import PropTypes from 'prop-types';

const DatasetPrivacy = ({ type }) => {
  return type === 'Public' ? (
    <span title="Anyone with Link">
      <i className="ti ti-link"></i>
    </span>
  ) : (
    //Private
    <span title="Only Me">
      <i className="ti ti-lock-1"></i>
    </span>
  );
};

DatasetPrivacy.propTypes = {
  type: PropTypes.string,
};

DatasetPrivacy.defaultProps = {
  type: 'Private',
};

export default DatasetPrivacy;
