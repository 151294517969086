import axios from 'axios';
import { RestService } from '@tuva-ui/components';

class ContentOverviewAPI extends RestService {
  getContent(lessonId, type='lessons') {
    return axios({
      method: 'get',
      url: `/api/${type}/${lessonId}/overview/`,
      headers: { 'Content-Type': 'application/json' },
    })
      .then(response => response.data)
      .catch(error => ({ error }));
  }
}

const ContentOverviewService = new ContentOverviewAPI();

export default ContentOverviewService;
