import axios from 'axios';
import Util from './Util';

class RestService {
  constructor() {
    axios.defaults.withCredentials = true;
    axios.defaults.headers.common['X-CSRFToken'] = this.getCookie('csrftoken');
  }

  getDiscount(type) {
    return axios({
      method: 'get',
      url: '/api/lookup/settings/?filter=' + type,
      headers: { 'Content-Type': 'application/json' },
    })
      .then(response => ({ response: response.data }))
      .catch(error => ({ error }));
  }

  whoami() {
    //removed catch block, so errors are handled in the respective calling methods
    return axios({
      method: 'get',
      url: '/api/whoami/',
      headers: { 'Content-Type': 'application/json' },
    }).then(response => response.data);
  }

  getAppConfig() {
    return axios({
      method: 'get',
      url: '/api/app_config/',
      headers: { 'Content-Type': 'application/json' },
    })
      .then(response => Util.normalizeAppConfData(response.data))
      .catch(error => ({ error }));
  }

  getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      let cookies = document.cookie.split(';');
      for (let cookie of cookies) {
        cookie = cookie.trim();
        if (cookie.substring(0, name.length + 1) === name + '=') {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }

    return cookieValue;
  }
}

export default RestService;
