import { markdown } from '@codemirror/lang-markdown';
import React, { useState } from 'react';

function LanguageSupport({ content, cssString }) {
  const [show, setShow] = useState(
    cssString.indexOf('accordion:open') >= 0 ? true : false,
  );

  // Styles are pure css string
  function cssToReactStyle() {
    const styleObject = {};

    // Split the CSS string into individual style declarations
    const declarations = cssString.split(';');

    declarations.forEach(declaration => {
      // Split each declaration into property and value
      const [property, value] = declaration.split(':');
      if (property && value) {
        // Convert the CSS property to camelCase
        const camelCaseProperty = property
          .trim()
          .replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
        // Add the property and value to the style object
        styleObject[camelCaseProperty] = value.trim();
      }
    });
    return styleObject;
  }

  let isLanguageSupport =
    cssString.indexOf('language-support:true') >= 0 ? true : false;

  return (
    <div
      onClick={e => {
        setShow(!show);
      }}
      style={cssToReactStyle()}
    >
      {isLanguageSupport ? (
        <>
          <div className="d-flex font-weight-semibold flex-justify-between">
            Language Support
            <span
              className="ti ti-arrow-right d-inline-block"
              style={{ transform: show ? 'rotate(90deg)' : 'rotate(270deg)' }}
            ></span>
          </div>
          {show && content}
        </>
      ) : (
        <div className="position-relative">
          <span
            className="position-absolute ti ti-arrow-right d-inline-block"
            style={{
              transform: show ? 'rotate(90deg)' : 'rotate(270deg)',
              right: '3px',
            }}
          ></span>
          <div className={`markdown-accordian ${show ? 'open' : 'close'}`}>
            {content}
          </div>
        </div>
      )}
    </div>
  );
}

export default LanguageSupport;
