import React, { useState, useCallback, useEffect } from 'react';
import { Loading, AnchorWithoutLink } from '@tuva-ui/components';
import DistrictService from './District.service';
import InfoBlock from './InfoBlock';
import './style.scss';

function GetDistrictUnitContent({ districtSlug, isFromBookmark }) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  let getContentDetails = useCallback(() => {
    setIsLoading(true);

    DistrictService.getDistrictUnitContent(districtSlug)
      .then(function (data) {
        if ([403, 500].includes(data.status)) {
          return;
        }

        setData(data);
        setIsLoading(false);
      })
      .catch(function (err) {
        setIsLoading(false);
        console.log('Error while fetching school content details', err);
      });
  }, []);

  useEffect(() => {
    if (districtSlug) {
      getContentDetails();
    } else if (isFromBookmark) {
      // we need to show empty screen though teacher is not part of any district
      setIsLoading(false);
    }
  }, [getContentDetails, isFromBookmark, districtSlug]);

  return (
    <>
      {isLoading ? (
        <div
          style={{ height: '100%' }}
          className="d-flex flex-items-center flex-justify-center mt-6"
        >
          <Loading label="Loading ..."></Loading>
        </div>
      ) : data.length === 0 ? (
        <div
          className={
            'bg-white d-flex flex-column placeholder-block flex-items-center flex-justify-center text-center'
          }
        >
          <div className="px-3 mb-1 mx-6" style={{ maxWidth: '100ch' }}>
            <h4 className="text-center">District Alignment</h4>
            Tuva's District Alignment streamlines the integration process by
            aligning educational content with your district's curriculum
            standards, saving valuable time and ensuring precision in content
            selection.{' '}
            <AnchorWithoutLink
              className={'text-underline'}
              onClick={() => {
                window.revealModal('contact-us');
              }}
            >
              Contact us
            </AnchorWithoutLink>{' '}
            for more details.
          </div>
        </div>
      ) : (
        data.map(data => <InfoBlock key={data.group_name} grade={data} />)
      )}
    </>
  );
}

export default GetDistrictUnitContent;
