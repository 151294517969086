import React from 'react';
import PropTypes from 'prop-types';

const Edit = ({ url, title }) => {
  return (
    <a
      className="p-1 pr-2"
      title={title}
      target="_blank"
      href={url}
      rel="noreferrer"
    >
      <i className="ti ti-pencil" title="Edit"></i>
    </a>
  );
};

Edit.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
};

Edit.defaultProps = {
  title: 'Edit',
};

export default Edit;
