import { useCallback, useEffect, useState } from 'react';
import './App.scss';
import LoginModal from './modals/Login';
import PricingModal from './modals/Pricing';

function App() {
  const [modalName, setModalName] = useState();
  const [subscription, setSubscription] = useState('premium');
  const onCancel = useCallback(() => {
    setModalName();
  }, []);

  useEffect(() => {
    window.openCommonModal = (modelName, subscription) => {
      setModalName(modelName);
      if (subscription) {
        setSubscription(subscription);
      }
    };
  }, []);

  switch (modalName) {
    case 'pricing':
      return <PricingModal onCancel={onCancel} subscription={subscription} />;
    case 'login':
      return <LoginModal onCancel={onCancel} />;
    default:
      return <></>;
  }
}

export default App;
