export const TUVA_TOOL_MENUS = {
  reset: 'Reset',
  dot: 'Dot',
  line: 'Line',
  pie: 'Pie',
  bar: 'Bar',
  box: 'Box',
  'bar-chart': 'Bar Chart',
  'stacked-bar-chart': 'Stacked Bar Chart',
  'stretched-bar-chart': 'Stretched Bar Chart',
  histogram: 'Histogram',
  'box-plot': 'Box Plot',
  'box-plot-with-outliers': 'Box Plot with Outliers',
  'box-and-dot-plot': 'Box and Dot Plot',
  'exclude-selected-cases': 'Exclude Selected Cases',
  'keep-only-selected-cases': 'Keep Only Selected Cases',
  'reset-all-filters': 'Reset All Filters',
  'swap-x-and-y-axes': 'Swap X and Y Axes',
  count: 'Count',
  'count-cases': 'Count Cases',
  'count-by-section': 'Count by Section',
  totals: 'Totals',
  percent: 'Percent',
  'percent-of-cases': 'Percent of Cases',
  'percent-by-section': 'Percent By Section',
  'overall-percent': 'Overall Percent',
  'percent-column-wise': 'Percent Column-wise',
  'percent-row-wise': 'Percent Row-wise',
  mean: 'Mean',
  median: 'Median',
  mode: 'Mode',
  'standard-deviation': 'Standard Deviation',
  'best-fit': 'Least Squares Line',
  'least-squares-line': 'Least Squares Line',
  'least-squares-line-by-category': 'Lines by Category',
  dividers: 'Dividers',
  'reference-lines': 'Reference Lines',
  'ref-line-on-x': 'Add Reference Line on X',
  'ref-line-on-y': 'Add Reference Line on Y',
  'ref-line-on-y2': 'Add Reference Line on Y2',
  'movable-line': 'Movable Line',
  'movable-line-on-x-y': 'Add Movable Line on X-Y',
  'movable-line-on-x-y2': 'Add Movable Line on X-Y2',
  plus: '+',
  minus: '-',
  'minus-plus': '- +',
  'case-card': 'CASE CARD',
  'ti-table-view': 'TABLE VIEW',
  'ti-summary-view': 'SUMMARY VIEW',
  'line-graph': 'Line Graph',
  'line-graph-by-category': 'Line Graph by Category',
  'percent-by-category': 'Percent by Category',
  'count-by-category': 'Count by Category',
  filter: 'Filter Icon',
  pencil: 'Edit Icon',
  functions: 'Stats',
  menu: 'Stats',
  stats: 'Stats',
  download: 'camera Icon',
  'order-cases-by': 'Order Cases By',
  map: 'Map',
  undo: 'Undo',
  redo: 'Redo',
  help: 'Help Icon',
  'more-options': 'Cog',
  'swap-xy-axes': 'Swap XY Axes',
  'ti-about-dataset': 'About',
  'ti-keywords': 'Vocabulary',
  'ti-glossary': 'Glossary',
  'ti-tutorial-video': 'Tutorial Video',
  'ti-text-size': 'Text Size',
  'ti-circle': 'Circle',
  'ti-annotate': 'Annotate',
  'ti-text': 'Text',
  'ti-arrow': 'Arrow',
  'ti-clear-all': 'Clear All',
  'ti-clear-all-reference-lines': 'Clear All Reference Lines',
  'ti-f-of-x': 'f(x)',
  'ti-attribute-search': 'Search Icon',
  'ti-hide-unhide-attribute': 'Eye Icon',
  'ti-add-new-attribute': 'Plus Icon',
  /**********************************/
  /******* TUVA JUNIOR ICONS ********/
  /**********************************/

  // tuva-jr play area icons
  'ti-jr-reset': 'Reset',
  'ti-jr-spread': 'Spread',
  'ti-jr-icons': 'Icons',
  'ti-jr-n-count': 'N Count',
  'ti-jr-plot-view': 'Plot View',
  // tuva-jr play area icons end

  'ti-jr-play-view': 'Play View',
  'ti-jr-undo"': 'Undo',
  'ti-jr-redo"': 'Redo',
  'ti-jr-dot': 'Dot',
  'ti-jr-line': 'Line',
  'ti-jr-line-graph': 'Line Graph',
  'ti-jr-line-graph-by-category': 'Line Graph by Category',
  'ti-jr-pie': 'Pie',
  'ti-jr-bar': 'Bar',
  'ti-jr-bar-chart-of-frequencies': 'Bar Chart of Frequencies',
  'ti-jr-bar-chart-of-values': 'Bar Chart of Values',
  'ti-jr-stacked-bar-chart': 'Stacked Bar Chart',
  'ti-jr-count': 'Count',
  'ti-jr-count-cases': 'Count Cases',
  'ti-jr-count-by-section': 'Count by Section',
  'ti-jr-count-by-category': 'Count by Category',
  'ti-jr-count-by-pie-section': 'Count by Pie Section',
  'ti-jr-count-by-bar-section': 'Count by Bar Section',
  'ti-jr-totals': 'Totals',
  'ti-jr-sum-by-bar': 'Sum by Bar Section',
  'ti-jr-bar-totals': 'Bar Totals',
  'ti-jr-percent-of-cases': 'Percent of Cases',
  'ti-jr-percent-by-category': 'Percent by Category',
  'ti-jr-percent-by-pie-section': 'Percent by Pie Section',
  'ti-jr-percent-by-bar-section': 'Percent by Bar Section',
  'ti-jr-percent-by-section': 'Percent By Section',
  'ti-jr-overall-percent': 'Overall Percent',
  'ti-jr-percent-column-wise': 'Percent Column-wise',
  'ti-jr-percent-row-wise': 'Percent Row-wise',
  'ti-jr-mean': 'Mean',
  'ti-jr-median': 'Median',
  'ti-jr-mode': 'Mode',
  'ti-jr-show-labels': 'Show labels',
  'ti-jr-dividers': 'Dividers',
  'ti-jr-movable-line': 'Add Movable Line on X-Y',
  'ti-jr-ref-line-on-x': 'Add Reference Line on X',
  'ti-jr-ref-line-on-y': 'Add Reference Line on Y',
  'ti-jr-clear-all-reference-lines': 'Clear All Reference Lines',
  'ti-jr-exclude-selected-cases': 'Exclude Selected Cases',
  'ti-jr-keep-only-selected-cases': 'Keep Only Selected Cases',
  'ti-jr-reset-all-filters': 'Reset All Filters',
  'ti-jr-filter-by': 'Filter By',
  'ti-jr-pencil': 'Edit Icon',
  'ti-jr-circle': 'Circle',
  'ti-jr-text': 'Text',
  'ti-jr-arrow': 'Arrow',
  'ti-jr-annotate-line': 'Line',
  'ti-jr-clear-all': 'Clear All',
  'ti-jr-edit-attribute': 'Attribute Edit',
  'ti-jr-attribute-search': 'Search Icon',
  'ti-jr-table-view': 'TABLE VIEW',
  'ti-jr-summary-view': 'SUMMARY VIEW',
  'ti-jr-case-card': 'CASE CARD',
  'ti-jr-prev': 'Left Arrow',
  'ti-jr-next': 'Right Arrow',
  'ti-jr-text-size': '(T) Text Size',
  'ti-jr-order-cases-by': 'Order Cases By',
  'ti-jr-keyboard': 'Keyboard Shortcuts Icon',
  'ti-jr-map': 'Map',
  'ti-jr-num-perc': 'Count',
  'ti-jr-average': 'Averages',
  'ti-jr-model': 'Lines',
  'ti-jr-filter': 'Filter',
  'ti-jr-picture': 'Picture',
  'ti-jr-annotate': 'Annotate',
  'ti-jr-more': 'More',
  'ti-jr-plot-settings': 'Settings',
  'ti-jr-expand-table': 'Expand Table',
  'ti-jr-collapse-table': 'Collapse Table',
  'ti-jr-swap-xy-axes': 'Swap X and Y Axes',
  'ti-jr-plus': 'Decrease Bin Count is +',
  'ti-jr-minus': 'Decrease Bin Count is -',
  'ti-jr-axis-settings': 'Axis Settings',
  'ti-context-menu': 'Context Menu',
};
