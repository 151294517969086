import React, { useCallback, useEffect, useState } from 'react';
import loadScript from 'load-script';
import './style.scss';
import DetectGoogleTranslateChange from './DetectGoogleTranslateChange';

const languages = {
  ar: 'Arabic',
  'zh-CN': 'Chinese (Simplified), Cantonese',
  'zh-TW': 'Chinese (Traditional), Mandarin',
  en: 'English',
  fr: 'French',
  de: 'German',
  ht: 'Haitian Creole',
  ko: 'Korean',
  lt: 'Lithuanian',
  pt: 'Portuguese',
  es: 'Spanish',
  tr: 'Turkish',
};

const pageLanguage = 'en';

// Create a div and append it to the body
let googleTranslateElementWrapper = null;

// The google translate script callback
const googleTranslateElementInit = language => {
  // Empty the content of the google_translate_element div
  googleTranslateElementWrapper.innerHTML = '';
  // Set cookie to pick the default laguage on load
  document.cookie = `googtrans=/en/${language}; expires=Session; path=/`;
  // Initialize the google translate element
  const googleTranslateElement = new window.google.translate.TranslateElement(
    {
      pageLanguage: pageLanguage,
      includedLanguages: Object.keys(languages).join(','),
      autoDisplay: false,
    },
    'google_translate_element',
  );
};

// Set language on the loaded google translate element
const changeTranslatorLanguage = function (language) {
  const selectBox =
    googleTranslateElementWrapper.getElementsByTagName('select')[0];
  selectBox.value = language;
  selectBox.dispatchEvent(new Event('change'));
};

export function initTranslator() {
  if (!document.getElementById('google_translate_element')) {
    DetectGoogleTranslateChange();
    googleTranslateElementWrapper = document.createElement('div');
    googleTranslateElementWrapper.id = 'google_translate_element';
    document.body.appendChild(googleTranslateElementWrapper);
    googleTranslateElementWrapper.style.display = 'none';

    // Remove googtrans cookie on page unload
    window.addEventListener('beforeunload', () => {
      document.cookie =
        'googtrans=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    });
  }
}

// Component
function GoogleTranslate() {
  const [isLoading, setIsLoading] = useState(false);
  const [language, setLanguage] = useState(pageLanguage);
  const translate = useCallback(e => {
    setLanguage(e.target.value);
    if (!window.google?.translate) {
      setIsLoading(true);
      loadScript('//translate.google.com/translate_a/element.js', () => {
        setTimeout(() => {
          googleTranslateElementInit(e.target.value);
          setIsLoading(false);
        }, 500);
      });
    } else {
      changeTranslatorLanguage(e.target.value);
    }
  }, []);

  useEffect(() => {
    // Retain the selected language on component load when google translate is already loaded
    if (window.google?.translate) {
      // read cookie to set the default language
      const language = RegExp(/googtrans=\/en\/([a-z-]+)/).exec(
        document.cookie,
      );
      if (language) {
        setLanguage(language[1]);
      }
    }
  }, []);

  return (
    <div className="ml-2 skiptranslate">
      <div>
        <select
          className="goog-te-combo"
          aria-label="Language Translate Widget"
          onChange={translate}
          disabled={isLoading}
          value={language}
        >
          {Object.entries(languages).map(([code, name]) => (
            <option key={code} value={code}>
              {name}
            </option>
          ))}
        </select>
      </div>

      <span className="no-wrap">
        Powered by{' '}
        <a href="https://translate.google.com" target="_blank" rel="noreferrer">
          <img
            src="https://www.gstatic.com/images/branding/googlelogo/1x/googlelogo_color_42x16dp.png"
            width="37px"
            height="14px"
            alt="Google Translate"
            style={{ verticalAlign: 'middle' }}
          />{' '}
          Translate
        </a>
      </span>
    </div>
  );
}

export default GoogleTranslate;
