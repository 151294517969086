import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AnchorWithoutLink } from '@tuva-ui/components';
import DeleteModal from './DeleteModal';

const Delete = ({ title, tooltip, isIconOnly, OnDelete }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  return (
    <>
      <AnchorWithoutLink
        className="p-1 deleteCourseDataset no-underline"
        title={tooltip}
        onClick={() => setShowDeleteModal(true)}
      >
        {isIconOnly ? (
          <i className="ti ti-trash" title={tooltip}></i>
        ) : (
          'Delete'
        )}
      </AnchorWithoutLink>
      {showDeleteModal && (
        <DeleteModal
          title={title}
          isOpen={showDeleteModal}
          onSubmit={() => {
            OnDelete();
            setShowDeleteModal(false);
          }}
          onClose={() => setShowDeleteModal(false)}
        />
      )}
    </>
  );
};

Delete.propTypes = {
  title: PropTypes.string,
  tooltip: PropTypes.string,
  isIconOnly: PropTypes.bool,
  OnDelete: PropTypes.func,
};

Delete.defaultProps = {
  isIconOnly: true,
};

export default Delete;
