// import {Promise} from 'es6-promise';
import axios from 'axios';

const Environment = window.Environment;

class GoogleAPI {
  authenticateForClassroom() {
    return new Promise((resolve, reject) => {
      const client = window.google.accounts.oauth2.initCodeClient({
        client_id: Environment.google_client_id,
        scope:
          'https://www.googleapis.com/auth/classroom.courses.readonly\
              https://www.googleapis.com/auth/classroom.rosters.readonly\
              https://www.googleapis.com/auth/classroom.profile.emails\
              https://www.googleapis.com/auth/classroom.coursework.students\
              https://www.googleapis.com/auth/classroom.announcements',
        callback: tokenResponse => {
          const options = {
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
          };
          var formData = new FormData();
          formData.append('authCode', tokenResponse.code);
          axios
            .post('/account/integration/google/', formData, options)
            .then(function (result) {
              resolve(true);
            })
            .catch(function (err) {
              resolve(false);
            });
        },
        error_callback: error => {
          //send response when user close the popup
          resolve(false);
        },
      });
      client.requestCode();
    });
  }
  // Revoke auth/refresh token for google classroom
  revokeForClassroom() {
    return new Promise((resolve, reject) => {
      axios
        .post('/account/integration/google/revoke/')
        .then(function (result) {
          // For success
          if (result.success) {
            resolve(true);
          } else {
            // For failure
            resolve(false);
          }
        })
        .catch(function (err) {
          // For failure
          resolve(false);
        });
    });
  }

  postAssignmentToGoogle(assignmentId) {
    return axios
      .post(`/assignment/google_stream_post/${assignmentId}/`)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        return;
      });
  }

  assignDatasetToGoogleClassroom(data) {
    return axios
      .post(
        `/api/teacher/classrooms/${data.classroomId}/sharewith-google/`,
        data,
      )
      .then(response => {
        return response.data;
      })
      .catch(err => {
        return;
      });
  }
}

const GoogleService = new GoogleAPI();

export default GoogleService;
