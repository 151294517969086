import React from 'react';
import { Button } from '@tuva-ui/components';

function TextSizePicker({ setClassType, classType, isStaffUser }) {
  return (
    <div>
      {!isStaffUser && (
        <span className="d-none d-md-inline-block mr-2 mr-lg-2">Text Size</span>
      )}
      <span className="no-wrap skiptranslate">
        <Button
          className={
            'btn btn-sm ' +
            (classType === 'standard' ? 'btn-primary' : 'btn-outline')
          }
          onClick={e => setClassType('standard')}
        >
          Aa
        </Button>
        <Button
          className={
            'btn btn-sm ml-2 ml-lg-2 ' +
            (classType === 'large' ? 'btn-primary' : 'btn-outline')
          }
          onClick={e => setClassType('large')}
          style={{ fontSize: '16px' }}
        >
          Aa
        </Button>
        <Button
          className={
            'btn btn-sm ml-2 ml-lg-2 ' +
            (classType === 'extralarge' ? 'btn-primary' : 'btn-outline')
          }
          onClick={e => setClassType('extralarge')}
          style={{ fontSize: '20px' }}
        >
          Aa
        </Button>
      </span>
    </div>
  );
}

export default TextSizePicker;
