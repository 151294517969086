import React from 'react';
import { Button } from '@tuva-ui/components/';
function TextSizePickerButton({
  toggleShowTextSizePicker,
  className,
  showTextSizePicker,
}) {
  return (
    <Button
      className={`ml-3 border-0 s2 ${className ? className : ''}`}
      onClick={e => {
        toggleShowTextSizePicker();
      }}
      style={{
        background: 'none',
        transform: showTextSizePicker ? 'rotate(270deg)' : 'rotate(90deg)',
      }}
    >
      <span className="ti ti-arrow-right"></span>
    </Button>
  );
}

export default TextSizePickerButton;
