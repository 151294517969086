import React from 'react';
import PropTypes from 'prop-types';
import { ZoomableImage, MarkdownInline, Util } from '@tuva-ui/components';

export const ANSWER_DISPLAY_FORMAT = {
  ALPHABETS: 'alphabets',
};

function DisplayAnswer({ answer, showUserAnswer, displayFormat }) {
  let answerDisplay = '';

  if (answer.type === 'checkbox' || answer.type === 'radio') {
    answerDisplay =
      displayFormat === ANSWER_DISPLAY_FORMAT.ALPHABETS
        ? Util.getAlphabetFromNumbers(answer.userAnswer)
        : Util.getOptionFromIndex(
            answer.options,
            showUserAnswer ? answer.userAnswer : answer.answer,
          );
    if (displayFormat !== ANSWER_DISPLAY_FORMAT.ALPHABETS) {
      answerDisplay = answerDisplay.map((answer, key) => {
        return (
          <ul key={key}>
            <li>
              <MarkdownInline value={answer} isDisabled={false} />
            </li>
          </ul>
        );
      });
    }
  } else if (answer.type === 'graph') {
    answerDisplay = answer.preview;
  } else {
    answerDisplay = answer.userAnswer;
  }

  return (
    <>
      {answer.type === 'graph' ? (
        <div className="unstyled">
          <ZoomableImage src={answerDisplay} alt="" width="200" />
        </div>
      ) : (
        <span>{answerDisplay}</span>
      )}
    </>
  );
}

DisplayAnswer.propTypes = {
  answer: PropTypes.shape({
    type: PropTypes.string,
    userAnswer: PropTypes.any,
    answer: PropTypes.any,
    preview: PropTypes.string,
    options: PropTypes.array,
  }),
  displayFormat: PropTypes.string,
  showUserAnswer: PropTypes.bool,
};

DisplayAnswer.defaultProps = {
  showUserAnswer: false,
  displayFormat: '',
};

export default DisplayAnswer;
