import React from 'react';

function StepHeaderRibbon({ isTeacherOnlyView }) {
  return (
    <>
      {isTeacherOnlyView && (
        <span
          className="bg-green-2 text-green font-weight-bold color-gray-0 text-center"
          style={{ fontSize: '12px', paddingTop: '2px', paddingBottom: '2px' }}
        >
          TEACHER ONLY
        </span>
      )}
      {!isTeacherOnlyView && (
        <span
          className="bg-yellow-1 font-weight-bold text-gray color-green-7 text-center"
          style={{ fontSize: '12px', paddingTop: '2px', paddingBottom: '2px' }}
        >
          STUDENT VIEW
        </span>
      )}
    </>
  );
}

export default StepHeaderRibbon;
