import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const QUESTION_REGEX =
  /([\\?]) ?\[([0-9a-z_].*?)\]({.*})? ?\[?(.*?)\]? ?([=])/g;

/**
 * ComponentVisible Hook
 * @param {boolean} initialIsVisible
 */
function useComponentVisible(initialIsVisible) {
  const [isComponentVisible, setIsComponentVisible] =
    useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
}

/**
 * StepDropDown Functional Component
 * @param {*} props
 */
function StepDropDown({ steps, currentStepId, baseURL, readOnly }) {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const currentStepIndex = steps.findIndex(
    step => step.id === parseInt(currentStepId),
  );

  if (steps.length === 0) {
    return '';
  }

  if (readOnly) {
    return (
      <div>
        Step {currentStepIndex + 1} of {steps.length}
      </div>
    );
  }

  return (
    <div
      className="step-selector border rounded-1 mt-2"
      onClick={e => {
        setIsComponentVisible(true);
      }}
    >
      <span>Step {currentStepIndex + 1}</span> of {steps.length}
      <div className="dropdown-caret ml-1"></div>
      <ul
        className="m-0"
        ref={ref}
        style={{ display: isComponentVisible ? 'block' : 'none' }}
      >
        {steps.map((step, index) => {
          let isQues = step.content.match(QUESTION_REGEX);
          return (
            <li
              key={index}
              className={
                'list-style-none' +
                (step.id === parseInt(currentStepId) ? ' text-bold' : '')
              }
            >
              <Link
                to={`${baseURL}/${step.id}/`}
                onClick={e => {
                  e.stopPropagation();
                  setIsComponentVisible(false);
                }}
              >
                Step {index + 1} {isQues ? ': Question' : ''}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default StepDropDown;
