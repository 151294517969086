import React from 'react';
import PropTypes from 'prop-types';

const ArchivedBlock = ({ noOfActivities, className }) => {
  return (
    <div className={`bg-yellow text-center ${className}`}>
      <div className="s2">Archived</div>
      <p className="p2">
        {noOfActivities > 0
          ? 'This dataset and linked activities have been archived due to it being out of date.'
          : 'This dataset has been archived due to it being out of date.'}
      </p>
    </div>
  );
};

ArchivedBlock.propTypes = {
  noOfActivities: PropTypes.number,
  className: PropTypes.string,
};

ArchivedBlock.defaultProps = {
  className: 'py-2',
};

export default ArchivedBlock;
