import React, { useCallback } from 'react';
import { Form, BasicModal, Input } from '@tuva-ui/components/';

function LoginModal({ onCancel }) {
  const onSubmit = useCallback(data => {
    console.log('Submitted!');
  }, []);
  return (
    <BasicModal isOpen>
      <BasicModal.Header headerText="Login" />
      <BasicModal.Body>
        <div className="col-12">
          <Form
            submitText={'Submit'}
            cancelText="Cancel"
            layout={Form.LAYOUT.VERTICAL}
            onSubmit={onSubmit}
            onCancel={onCancel}
          >
            <Form.Item
              label="Field 1*"
              name="field_1"
              rules={[{ required: true }]}
            >
              <Input.Text />
            </Form.Item>
          </Form>
        </div>
      </BasicModal.Body>
    </BasicModal>
  );
}

export default LoginModal;
