import React from 'react';
import { TuvaModal } from '@tuva-ui/components/';

function Vocabulary({ datasetVocabulary, show, onClose }) {
  return (
    <TuvaModal
      isOpen={show}
      onClose={e => onClose()}
      className="popup-large"
      style={{
        minHeight: '200px',
        padding: '16px 24px',
      }}
    >
      <h4 className="border-bottom border-gray-200 pb-3">Vocabulary</h4>
      {datasetVocabulary?.map((vocabulary, index) => {
        return (
          <div key={index}>
            <h5 className="pt-3 s2">{vocabulary.keyword}</h5>
            <div
              dangerouslySetInnerHTML={{
                __html: vocabulary.keyword_details,
              }}
            ></div>
          </div>
        );
      })}
    </TuvaModal>
  );
}

export default Vocabulary;
