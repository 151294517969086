import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AnchorWithoutLink } from '@tuva-ui/components';
import Gsheet from './Gsheet';
import MetadataModel from '../../MetadataModel/MetadataModel';
import NotesHistoryModel from '../../NotesHistoryModel/NotesHistoryModel';
import DeleteModal from './DeleteModal';
const Popup = ({
  dataset,
  hasGoogleDriveAccess,
  showPopup,
  appTypeBaseUrl,
  OnTogglePopup,
  OnDelete,
  isCourseDataset,
  isPlotView,
  datasetType,
}) => {
  const [showMetadataModal, setShowMetadataModal] = useState(false);
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const pageRef = useRef();

  useEffect(() => {
    //handle click outside for Action popup - closed when click outside of Action popup
    const handleClickOutside = event => {
      if (pageRef.current && !pageRef.current.contains(event.target)) {
        OnTogglePopup(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [pageRef, OnTogglePopup]);

  return (
    <div className="d-inline-block position-relative mt-n3" ref={pageRef}>
      <i
        className="ti ti ti-more-horizontal text-green v-align-text-top cursor-pointer"
        style={{ fontSize: '24px' }}
        onClick={() => {
          OnTogglePopup(true);
        }}
      ></i>
      {showPopup && (
        <div
          className="border mt-1 border-green px-2 bg-gray-c-0"
          style={{
            width: '153px',
            position: 'absolute',
            top: '100%',
            right: '-55px',
            borderRadius: '3px',
            zIndex: '999',
          }}
        >
          <div className="mt-2">
            {hasGoogleDriveAccess && <Gsheet url={dataset.gdrive_url} />}
          </div>
          <div className="mt-2">
            {dataset.type === 'dataset' && !dataset.is_draw_dataset && (
              <a
                className="p-1"
                title="Table View"
                target="_blank"
                href={`/tableview/dataset/${dataset.access_url}/`}
                rel="noreferrer"
              >
                Table View
              </a>
            )}
          </div>

          <div className="mt-2">
            {!dataset.is_draw_dataset && (
              <a
                className="p-1 pr-2"
                title={
                  dataset.type === 'dataset' ? 'Edit Dataset Metadata' : 'Edit'
                }
                target="_blank"
                href={
                  isCourseDataset
                    ? `${dataset.get_admin_url}`
                    : dataset.type === 'dataset'
                    ? `/dataset-uploader/partner/${dataset.id}/review/?from=%2Fdatasets%2F&update=1`
                    : `/insert-data-instance/${dataset.access_url}/`
                }
                rel="noreferrer"
              >
                {isCourseDataset ? 'Edit In Django' : 'Edit / Review'}
              </a>
            )}
          </div>
          <div className="mt-2">
            {!dataset.is_draw_dataset && isCourseDataset && (
              <a
                className="p-1"
                title="Table View"
                target="_blank"
                href={`${dataset.get_absolute_url}`}
                rel="noreferrer"
              >
                View
              </a>
            )}
          </div>
          {!isPlotView && (
            <>
              <div className="mt-2">
                <AnchorWithoutLink
                  className="p-1 pr-2 mt-2"
                  onClick={() => setShowNotesModal(true)}
                >
                  {'Notes / History'}
                </AnchorWithoutLink>
              </div>
              <div className="mt-2">
                <AnchorWithoutLink
                  className="p-1 pr-2 mt-2"
                  onClick={() => setShowMetadataModal(true)}
                >
                  Metadata Changes
                </AnchorWithoutLink>
              </div>
            </>
          )}
          <div className="my-2">
            <AnchorWithoutLink
              className="p-1 deleteCourseDataset"
              title={'Delete Dataset'}
              onClick={() => setShowDeleteModal(true)}
            >
              {'Delete'}
            </AnchorWithoutLink>
          </div>
        </div>
      )}

      {showMetadataModal && (
        <MetadataModel
          datasetId={dataset.id}
          type={datasetType ? datasetType : dataset.type}
          appTypeBaseUrl={appTypeBaseUrl}
          onClose={() => setShowMetadataModal(false)}
        />
      )}
      {showNotesModal && (
        <NotesHistoryModel
          appTypeBaseUrl={appTypeBaseUrl}
          datasetId={dataset.id}
          type={datasetType ? datasetType : dataset.type}
          onClose={() => setShowNotesModal(false)}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          appTypeBaseUrl={appTypeBaseUrl}
          title={dataset.name || dataset.dataset_name || dataset.title}
          isOpen={showDeleteModal}
          onSubmit={() => {
            OnDelete();
            setShowDeleteModal(false);
          }}
          onClose={() => setShowDeleteModal(false)}
        />
      )}
    </div>
  );
};

Popup.propTypes = {
  dataset: PropTypes.object,
  hasGoogleDriveAccess: PropTypes.bool,
  showPopup: PropTypes.bool,
  OnTogglePopup: PropTypes.func,
  onDelete: PropTypes.func,
  isCourseDataset: PropTypes.bool,
};

export default Popup;
