import React, { useState, useEffect, useCallback } from 'react';
import {
  MarkdownInline,
  TuvaModal,
  Loading,
  Author,
  AppConfAndUserContext,
  Constants,
} from '@tuva-ui/components/';
import ClassRooms from './ClassRooms';
import AssignToClass from './AssignToClass';
import AssignmentAPI from '../AssignmentService';

function Assign({ onClose, assignment, user, contentType }) {
  const { appConf } = React.useContext(AppConfAndUserContext);
  const [assignments, setAssignments] = useState();
  const [assignToClass, setAssignToClass] = useState();

  const isActivity = contentType === 'activity';

  const assignmentType = isActivity
    ? 'Activity'
    : Constants.LESSON_TYPE_MAPPING[assignment.lesson_type];
  const [title, setTitle] = useState('Assign ' + assignmentType);

  const stepsCount = isActivity
    ? assignment.no_of_cards
    : assignment.steps.length;

  const isAssignToGoogle = () => {
    return title === 'Google Classroom Access';
  };

  const fetchAssignments = useCallback(() => {
    AssignmentAPI.getAssignments(contentType, assignment.id)
      .then(function (data) {
        setAssignments(data);
      })
      .catch(function (data) {
        console.log('Unable to get assignments', data);
      });
  }, [contentType, assignment.id]);

  useEffect(() => {
    fetchAssignments();
  }, [fetchAssignments]);

  const askedBy = isActivity
    ? assignment.user || assignment.asked_by
    : assignment.author;

  return (
    <TuvaModal
      className="popup-medium"
      style={{ padding: '0' }}
      isOpen={true}
      onClose={onClose}
    >
      <h4 className="border-bottom border-gray-300 py-3 px-4">{title}</h4>
      {!assignments ? (
        <div className="py-6 text-center">
          <Loading show={true} />
        </div>
      ) : (
        !isAssignToGoogle() && (
          <div className="bg-green-0 p-4 mb-6">
            <div className=" p1 text-center">
              {assignments.classrooms.length === 0 ? (
                `To assign this ${assignmentType}, create your first class from your dashboard.`
              ) : (
                <MarkdownInline
                  value={
                    isActivity ? assignment.activity_title : assignment.title
                  }
                />
              )}
            </div>
            <div className="pt-4 text-center mb-3 d-flex flex-justify-center">
              {assignments.classrooms.length === 0 ? (
                <div>
                  <a href={`/dashboard/`} className="btn btn-primary btn-md">
                    Go to Dashboard
                  </a>
                </div>
              ) : (
                <div>
                  <Author
                    username={askedBy.username}
                    name={`${askedBy.title} ${askedBy.last_name}`}
                    isStaff={askedBy.is_staff}
                    isDoctrina={appConf.isDoctrina}
                  />
                  <i className="ti ti-steps-2 mr-2 ml-5"></i>
                  {stepsCount} Steps
                </div>
              )}
            </div>
          </div>
        )
      )}
      {assignToClass && (
        <AssignToClass
          classroom={assignToClass}
          assignment={assignment}
          contentType={contentType}
          assignmentType={assignmentType}
          onClose={e => {
            setAssignToClass(null);
          }}
          hasGClassToken={user.hasGClassToken}
          updateAssignments={(classroomId, assignmentId) => {
            const _assignments = Object.assign({}, assignments);
            const index = _assignments.classrooms.findIndex(
              classroom => classroom.id === classroomId,
            );
            if (index >= 0) {
              _assignments.classrooms[index].assignment_id = assignmentId;
            }
            setAssignments(_assignments);
          }}
          title={setTitle}
        />
      )}
      {assignments && !assignToClass && (
        <ClassRooms
          classrooms={assignments.classrooms}
          objectType={assignments.object_type}
          assignToClass={c => {
            setTitle('Assign ' + assignmentType);
            setAssignToClass(c);
          }}
        />
      )}
    </TuvaModal>
  );
}

export default Assign;
