import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
function CircularProgressBar({
  percentage,
  progressColor,
  innerCircleColor,
  textColor,
  className,
  labelText,
  count,
}) {
  const progressBar = useRef();
  useEffect(() => {
    const innerCircle = progressBar.current.children[0];
    const progressValue = progressBar.current.children[1];
    let startValue = 0,
      endValue = Number(percentage),
      speed = 20;

    const progress = setInterval(() => {
      progressValue.textContent = `${count}`;
      innerCircle.style.backgroundColor = innerCircleColor;
      if (progressBar?.current) {
        progressBar.current.style.background = `conic-gradient(${progressColor} ${
          startValue * 3.6
        }deg,transparent 0deg)`;
      }
      if (startValue === endValue) {
        clearInterval(progress);
      }
      startValue++;
    }, speed);
    return function cleanup() {
      clearInterval(progress);
    };
  }, [percentage, progressColor, count, innerCircleColor]);

  return (
    <div
      className={`d-flex flex-column flex-items-center ${className}`}
      style={{ color: textColor }}
    >
      <div
        ref={progressBar}
        className={`tuva-round-progress circular-progress`}
      >
        <div className="inner-circle"></div>
        <p className="percentage mb-0">0</p>
      </div>
      <p>{labelText}</p>
    </div>
  );
}

CircularProgressBar.propTypes = {
  percentage: PropTypes.number,
  innerCircleColor: PropTypes.string,
  textColor: PropTypes.string,
  progressColor: PropTypes.string,
};

CircularProgressBar.defaultProps = {
  percentage: 50,
  textColor: '#013CA6',
  progressColor: '#013CA6',
  innerCircleColor: '#E5EFFA',
};

export default CircularProgressBar;
