import React, { Component } from 'react';
import Button from '../Button/Button';
import TuvaToast from '../TuvaToast/TuvaToast';
import TuvaModal from '../TuvaModal';
import FeedbackAPI from '../FeedbackService';

class FeedBack extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: '',
      show: false,
    };
  }

  onChange = e => {
    this.setState({
      comment: e.target.value,
    });
  };

  state = {
    comment: '',
  };

  submitFeedback = () => {
    const { title, user } = this.props;
    let _data = {
      title: title,
      message: this.state.comment,
      username: user.username,
      url: window.location.href,
    };
    this.setState({
      isSubmitting: true,
    });
    FeedbackAPI.submitFeedback(_data).then(result => {
      if (result.response === 200) {
        TuvaToast.info('Feedback is submitted successfully.');
        this.onClose();
      } else {
        TuvaToast.error('Unable to submit feedback. Please try again later.', {
          hideProgressBar: true,
        });
      }
    });
  };

  onClose = () => {
    this.setState({
      show: false,
      isSubmitting: false,
      comment: '',
    });
  };

  onOpen = () => {
    if (this.props.user) {
      this.setState({ show: true });
    } else {
      window.revealModal('logIn');
    }
  };

  render() {
    const { show } = this.state;
    return (
      <>
        <span
          className="link-regular-9 d-none d-md-inline-block"
          onClick={this.onOpen}
        >
          Feedback
        </span>

        {show && (
          <TuvaModal
            isOpen={show}
            onClose={this.onClose}
            className="popup-large"
            style={{ padding: '0' }}
          >
            <h4 className="text-normal border-bottom border-gray-300 py-3 px-4">
              Feedback
            </h4>

            <div
              className="py-3 px-4"
              style={{
                maxHeight: 'calc(100vh - 200px)',
                overflow: 'auto',
              }}
            >
              <textarea
                className="form-control input-block"
                rows="8"
                onChange={this.onChange}
                value={this.state.comment}
              ></textarea>
              <div className="mt-3 mb-2">
                <Button
                  type="button"
                  onClick={e => this.submitFeedback()}
                  disabled={
                    this.state.isSubmitting || this.state.comment.length < 3
                  }
                  className="btn btn-primary"
                >
                  Send
                </Button>
              </div>
            </div>
          </TuvaModal>
        )}
      </>
    );
  }
}

export default FeedBack;
