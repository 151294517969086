import React from 'react';
import PropTypes from 'prop-types';
import Rate from 'rc-rate';
import 'rc-rate/assets/index.css';
import styled from 'styled-components';

const StyledRate = styled(Rate)`
  &.rc-rate {
    border: 1px solid transparent;
    &.error {
      border-color: red;
    }
    & > li > div {
      font-size: ${({ size }) => size}px;
    }
    .rc-rate-star-full .rc-rate-star-second {
      color: #1c2025;
    }
    &.without-margin .rc-rate-star {
      margin-right: 0px;
    }
  }
`;
function RateBlock({
  setValue,
  value,
  size,
  disabled,
  className,
  isRatedAlready,
  withMargin,
}) {
  return (
    <StyledRate
      size={size}
      onChange={setValue}
      value={value}
      disabled={disabled}
      className={withMargin ? className : `without-margin ${className}`}
      isRatedAlready={isRatedAlready}
    />
  );
}

RateBlock.propTypes = {
  setValue: PropTypes.func,
  value: PropTypes.number,
  size: PropTypes.number,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

RateBlock.defaultProps = {
  value: 0,
  size: 22,
  disabled: false,
  withMargin: false,
};

export default RateBlock;
