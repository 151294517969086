export const DISCOUNTS = {
  TEACHER: 'TEACHER_DISCOUNT',
  SCHOOL: 'SCHOOL_DISCOUNT',
  JUNIOR: 'JUNIOR_DISCOUNT',
};

export const USER_ROLES = {
  TEACHER: 'teacher',
  STUDENT: 'student',
};

export const FOOTER_BUTTONS = {
  NEXT: 'Next',
  // SAVE_ACTIVITY: 'Save Activity',
  PREVIOUS: 'Previous',
  ADD_STEP: 'Add Step',
};

export const CONTENT_BUILDER = {
  NEW_STEP_ID: 'new',
  EXIT_ACTIVITY: 'Exit Activity',
};

export const RECENTLY_PUBLISHED = {
  COUNT: 3,
};
export const MARKDOWN_AUTOCOMPLETE_TYPES = {
  ATTRIBUTES: 'attributes',
  ICONS: 'icons',
  ALERTICONS: 'alert-icons',
};

export const CONTENT_LIBRARY = {
  DEFAULT_SUBJECT_CATEGORY: 'math',
  OTHER_SUBJECT_CATEGORY: 'other',
};

export const ERROR_MESSAGE = {
  GENERIC_API_FAILURE:
    "Sorry! Something went wrong, but we're looking into it.",
};

export const TUVA_JUNIOR_FILTER_TYPE = 'jr';
export const TUVA_JR_FEATURED_TAG = 'Featured Tuva Jr.';
export const TUVA_JR_FEATURED_CRITERIA_NAME = 'Featured Tuva Jr. Lessons';

export const TUVA_JUNIOR_SLUG = 'tuva-jr';
export const GOOGLE_AUTH_INSUFFICIENT_SCOPE = 'Insufficient Scope';

export const LESSON_TYPE_MAPPING = {
  datastory: 'Data Story',
  taskassessment: 'Assessment',
  task: 'Task',
};

const Constants = {
  DISCOUNTS,
  USER_ROLES,
  FOOTER_BUTTONS,
  CONTENT_BUILDER,
  RECENTLY_PUBLISHED,
  MARKDOWN_AUTOCOMPLETE_TYPES,
  CONTENT_LIBRARY,
  ERROR_MESSAGE,
  TUVA_JUNIOR_FILTER_TYPE,
  TUVA_JUNIOR_SLUG,
  GOOGLE_AUTH_INSUFFICIENT_SCOPE,
  LESSON_TYPE_MAPPING,
  TUVA_JR_FEATURED_TAG,
  TUVA_JR_FEATURED_CRITERIA_NAME,
};
export default Constants;
