import React from 'react';
import PropTypes from 'prop-types';
import { TuvaModal } from '@tuva-ui/components/';
import './PremiumBlock.scss';

function PremiumBlock({ type, message, className, isTuvaJrApp }) {
  let updatedClassName =
    'premium-block text-center bg-yellow-5 d-flex flex-items-center border p-4 ' +
    className;
  if (type === 'Dataset') {
    updatedClassName += ' for-dataset';
  } else if (type === 'Datastory') {
    updatedClassName += ' mb-6';
  }
  return (
    <div className={updatedClassName}>
      <div className="flex-1">
        {!isTuvaJrApp && <h4 className="s2">This is a Premium {type}</h4>}
        <p className="p2">
          {message
            ? message
            : `Upgrade to a Tuva ${
                isTuvaJrApp ? 'Jr.' : 'Premium'
              } Subscription to access this and ${
                isTuvaJrApp ? '100' : '1000'
              }+ more activities, data stories, and datasets.`}
        </p>
        <a
          className="btn btn-primary btn-lg mt-4"
          target="_blank"
          rel="noreferrer"
          href={`/pricing/${isTuvaJrApp ? 'jr/' : 'premium/'}`}
        >
          Upgrade
        </a>
      </div>
    </div>
  );
}

export function PremiumBlockModal({
  type,
  message,
  isTuvaJrApp,
  onClose,
  modelType,
  modelOverlayStyle,
}) {
  return (
    <TuvaModal
      isOpen={true}
      onClose={onClose}
      className={`${modelType} ? ${modelType} : 'popup-small'`}
      style={{ padding: '0' }}
      modelOverlayStyle={modelOverlayStyle}
    >
      <PremiumBlock type={type} message={message} isTuvaJrApp={isTuvaJrApp} />
    </TuvaModal>
  );
}

PremiumBlockModal.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
  isTuvaJrApp: PropTypes.bool,
  onClose: PropTypes.func,
  modelOverlayStyle: PropTypes.object,
};

PremiumBlockModal.defaultProps = {
  message: '',
  isTuvaJrApp: false,
};

export default PremiumBlock;
