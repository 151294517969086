import React from 'react';

const Fork = () => {
  return (
    <span>
      <i className="ti ti-network-wired"></i>&nbsp;
    </span>
  );
};

export default Fork;
