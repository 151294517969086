import { RestService } from '@tuva-ui/components';
import axios from 'axios';

class ModalAPI extends RestService {
  getRoles() {
    return axios
      .get(`/api/misc/roles/`)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        return;
      });
  }

  saveRequestQuote(data) {
    return axios({
      method: 'Post',
      url: `/account/request-quote/`,
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
      },
      data: data,
    })
      .then(response => {
        return response;
      })
      .catch(error => ({ error }));
  }
}

const ModalService = new ModalAPI();
export default ModalService;
