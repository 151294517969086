import React from 'react';
import PropTypes from 'prop-types';
import { Util } from '@tuva-ui/components/';

function ContentType({ objectType, isIconOnly, textColor, isTuvaJr }) {
  if (!objectType) return <></>;

  let contentTypeIcon = '',
    contentTypeLabel = Util.getContentTypeName(objectType, isTuvaJr);

  switch (objectType) {
    case 'dataset':
      contentTypeIcon = 'ti-dataset';
      break;
    case 'datasetactivity':
    case 'activity':
      contentTypeIcon = 'ti-activities';
      break;
    case 'datastory':
    case 'lesson':
      contentTypeIcon = 'ti-datastories';
      break;
    case 'task':
      contentTypeIcon = 'ti-tasks';
      break;
    case 'taskassessment':
      contentTypeIcon = 'ti-assessments';
      break;
    case 'course':
      contentTypeIcon = 'ti-course';
      break;
    case 'collection':
      contentTypeIcon = 'ti-collections';
      break;
    default:
      break;
  }

  return isIconOnly ? (
    <i className={`ti ${contentTypeIcon}`}></i>
  ) : (
    <span
      className={`p2 m-0 ${
        textColor ? textColor : 'text-gray'
      } d-flex flex-items-center`}
    >
      <i className={`ti ${contentTypeIcon} mr-2 d-inline-flex`}></i>
      {contentTypeLabel}
    </span>
  );
}

ContentType.propTypes = {
  objectType: PropTypes.string,
  isIconOnly: PropTypes.bool,
};

ContentType.defaultProps = {
  objectType: undefined,
  isIconOnly: false,
};

export default ContentType;
