import axios from 'axios';

class AssignmentService {
  getAssignments(assignmentType, assignmentId) {
    return axios
      .get(`/api/assignments/${assignmentType}/${assignmentId}/`)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        return;
      });
  }

  addAssignments(assignmentType, assignmentId, obj) {
    return axios
      .post(`/api/assignments/${assignmentType}/${assignmentId}/`, obj)
      .then(response => {
        return response;
      })
      .catch(err => {
        return;
      });
  }
}

const AssignmentAPI = new AssignmentService();

export default AssignmentAPI;
