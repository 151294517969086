import React, { useRef, useImperativeHandle } from 'react';
import CodeMirror from './CodeMirror';
import MarkdownHeader from './MarkdownHeader';

const borderColors = {
  errorBorderColor: 'border-red',
  normalBorderColor: 'border-gray-500',
};
const MarkdownEditor = React.forwardRef(
  ({ autocompletionList, initialValue, inputId, onChange }, ref) => {
    const codeEditorRef = useRef();
    const markdownEditorRef = useRef();

    useImperativeHandle(ref, () => ({
      getValue: () => codeEditorRef.current.getValue(),
      applyError: () => {
        markdownEditorRef.current.className += ` ${borderColors.errorBorderColor}`;
        markdownEditorRef.current.classList.remove(
          borderColors.normalBorderColor,
        );
      },
      forceReRenderForSafari: () => {
        codeEditorRef.current.forceReRenderForSafari();
      },
    }));

    let _replaceSelection = function (start, end, insertBegin, insertEnd) {
      let transaction = codeEditorRef.current
        .getState()
        .update(
          { changes: { from: start, insert: insertBegin } },
          { selection: { ranges: { from: start, to: start } } },
          { changes: { from: end, insert: insertEnd } },
        );

      codeEditorRef.current.getView().dispatch(transaction);
      codeEditorRef.current.getView().focus();
      //set the cursor at the end of selection
      codeEditorRef.current
        .getView()
        .dispatch({ selection: { anchor: end + insertBegin.length } });
    };

    let _isOrderedUnorderedList = function (type) {
      return type === 'UnorderedList' || type === 'OrderedList';
    };

    let _populateStyle = function (type, start, end) {
      let insertBegin, insertEnd, isEndofSelection;
      if (type === 'bold') {
        insertBegin = '**';
        insertEnd = '**';
      } else if (type === 'italic') {
        insertBegin = '*';
        insertEnd = '*';
      } else if (type === 'NewLine') {
        insertBegin = ';;br';
        insertEnd = '';
      } else if (type === 'youtube') {
        insertBegin = '!![youtube][560x315](YOUTUBE_ID)';
      } else if (type === 'vimeo') {
        insertBegin = '!![vimeo][560x315](VIMEO_ID)';
      } else if (type === 'link') {
        insertBegin = '[Link_TEXT](URL)';
      } else if (type === 'img') {
        insertBegin = '![ALT_TEXT][100%](URL "Caption")';
      } else if (type === 'UnorderedList' || type === 'OrderedList') {
        let isOrderedList = type === 'OrderedList';
        let index = 1;
        insertBegin = isOrderedList ? index + '. ' : '* ';
        if (start !== end) {
          //apply style to each option
          let selectionEnd = codeEditorRef.current.getPosition()[0].to;
          isEndofSelection = end === selectionEnd ? true : false;

          while (!isEndofSelection) {
            _replaceSelection(start, end, insertBegin, insertEnd);
            let nextline = codeEditorRef.current.getView().state.doc.lineAt(
              end + insertBegin.length + 1, //next line index including appended string '* ' or '1.'
            );
            start = nextline.from;
            end = nextline.to;
            insertBegin = isOrderedList ? ++index + '. ' : '* ';
            isEndofSelection = nextline.to < selectionEnd ? false : true;
            selectionEnd = selectionEnd + insertBegin.length + 1;
          }
        }
      }

      _replaceSelection(start, end, insertBegin, insertEnd);
    };

    let applyStyle = function (type) {
      let start, end;
      start = codeEditorRef.current.getPosition()[0].from;
      end = codeEditorRef.current.getPosition()[0].to;

      if (_isOrderedUnorderedList(type)) {
        //This logic will apply style to all selected lines
        let line = codeEditorRef.current.getView().state.doc.lineAt(start);
        start = line.from;
        end = line.to;
      }

      _populateStyle(type, start, end);
    };

    let triggerAutocomplete = function (type) {
      codeEditorRef.current.showAutocomplete(type);
    };

    return (
      <div
        className={`markdownEditor border ${borderColors.normalBorderColor} mb-4`}
        ref={markdownEditorRef}
        id={inputId ? inputId : 'markdown-editor'}
      >
        <MarkdownHeader
          OnSelect={applyStyle}
          triggerAutocomplete={triggerAutocomplete}
        />
        <div
          onClick={() => {
            codeEditorRef.current.getView().focus();
            markdownEditorRef.current.className += ` ${borderColors.normalBorderColor}`;
            markdownEditorRef.current.classList.remove(
              borderColors.errorBorderColor,
            );
          }}
        >
          <CodeMirror
            id="editor"
            ref={codeEditorRef}
            onChange={onChange}
            initialValue={initialValue}
            autocompletionList={autocompletionList}
          />
        </div>
      </div>
    );
  },
);

export default MarkdownEditor;
