export const INTERESTED_CONTENT_OPTIONS = [
  'Math',
  'Science',
  'Other',
  'I would like to register for a Virtual PD Workshop',
  'I would like to schedule an Onsite PD Workshop',
];

export const SUBSCRIPTION_OPTIONS = [
  { key: 'Per Teacher Plan', value: 'Per Teacher Plan' },
  { key: 'Per School / Campus Plan', value: 'Per School / Campus Plan' },
  { key: 'Districtwide Plan', value: 'Districtwide Plan' },
];

const Constants = {
  INTERESTED_CONTENT_OPTIONS,
  SUBSCRIPTION_OPTIONS,
};
export default Constants;
