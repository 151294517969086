import React, { Component } from 'react';
import Loading from '../Loading';
import Button from '../Button/Button';

class GraphType extends Component {
  state = {
    isLoading: false,
  };

  focus() {
    this.button.focus();
  }

  captureState() {
    const { id, onValueChange, getPlotState, getPlotPreview } = this.props;
    this.setState({
      isLoading: true,
    });
    // Make it async
    setTimeout(() => {
      getPlotPreview(canvas => {
        onValueChange(id, getPlotState(), canvas.toDataURL('image/png'));
        this.setState({
          isLoading: false,
        });
      });
    }, 100);
  }

  removeCapturedState() {
    const { id, onValueChange } = this.props;
    onValueChange(id, null, null);
  }

  render() {
    const { isInvalidInput, value, preview, disabled } = this.props;
    return (
      <div
        className={
          'border text-center graph-question' +
          (value ? ' border-dashed' : '') +
          (isInvalidInput ? ' border-red' : '')
        }
      >
        <div style={{ width: '100%' }} className="bg-white d-table">
          <div
            className="d-table-cell v-align-middle"
            style={{ height: '200px' }}
          >
            {this.state.isLoading && <Loading />}
            {!this.state.isLoading && !value && <span>Save Your Work</span>}
            {!this.state.isLoading && preview && (
              <img style={{ maxWidth: '100%' }} src={preview} alt="" />
            )}
          </div>
        </div>
        <div
          className="p-2 bg-gray-light border-top"
          style={{ position: 'relative' }}
        >
          <Button
            className={
              'btn text-normal' +
              (value || this.state.isLoading ? ' btn-gray' : ' btn-primary') +
              (isInvalidInput ? ' border-red' : '')
            }
            onClick={e => {
              e.preventDefault();
              this.captureState();
            }}
            type="button"
            ref={el => (this.button = el)}
            disabled={disabled || this.state.isLoading || value}
          >
            {value ? 'Saved' : this.state.isLoading ? 'Saving' : 'Save'}
          </Button>
          {!disabled && value && (
            <Button
              className="btn-link"
              style={{ position: 'absolute', top: '5px', right: '5px' }}
              onClick={e => {
                e.preventDefault();
                this.removeCapturedState();
              }}
            >
              <i className="fa fa-trash"></i> Remove
            </Button>
          )}
        </div>
      </div>
    );
  }
}

export default GraphType;
