import React, { Component } from 'react';
import {
  MarkdownInline,
  TuvaModal,
  TuvaToast,
  Button,
} from '@tuva-ui/components/';
import BrowserInfo from './BrowserInfo.js';
import FeedbackAPI from '../FeedbackService';

class FeedBackModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: '',
    };
  }

  onChange = e => {
    this.setState({
      comment: e.target.value,
    });
  };

  state = {
    comment: '',
  };

  submitFeedback = () => {
    const { title, content, leftContent, user, onClose } = this.props;
    let _data = {
      title: title,
      message: this.state.comment,
      content: content,
      left_content: leftContent,
      username: user.username,
      url: window.location.href,
      browser_info: BrowserInfo(),
      user_type: user.type.toUpperCase(),
    };
    this.setState({
      isSubmitting: true,
    });
    FeedbackAPI.submitFeedback(_data).then(result => {
      this.setState({
        isSubmitting: false,
        comment: '',
      });
      if (result.response === 200) {
        TuvaToast.info('Feedback is submitted successfully.');
        onClose();
      } else {
        TuvaToast.error('Unable to submit feedback. Please try again later.', {
          hideProgressBar: true,
        });
      }
    });
  };

  render() {
    const { show, title, user, content, leftContent, onClose } = this.props;
    return (
      <TuvaModal isOpen={show} onClose={onClose} className="popup-large p-0">
        <h4 className="text-normal border-bottom border-gray-300 py-3 px-4">
          Feedback
        </h4>
        <div className="bg-green-0 p-4 p1 text-center">
          <MarkdownInline value={title} />
        </div>
        <div className="py-3 px-4">
          {user?.is_superuser && (
            <>
              {content && (
                <>
                  <div className="s3 mb-2">Content:</div>
                  <textarea
                    className="form-control input-block mb-3"
                    disabled
                    rows="8"
                    value={content}
                  ></textarea>
                </>
              )}
              {leftContent && (
                <>
                  <div className="mt-3 s3 mb-2">Left Content:</div>
                  <textarea
                    className="form-control input-block mb-3"
                    disabled
                    rows="6"
                    value={leftContent}
                  ></textarea>
                </>
              )}
            </>
          )}
          <div className="mb-2 text-bold">
            Your feedback is valuable. Tell us about what you're experiencing.
          </div>
          <textarea
            className="form-control input-block"
            rows="8"
            maxLength={1000}
            onChange={this.onChange}
            value={this.state.comment}
          ></textarea>
          <div className="mt-3 mb-2">
            <Button
              type="button"
              onClick={e => this.submitFeedback()}
              disabled={
                this.state.isSubmitting || this.state.comment.length < 3
              }
              className="btn btn-primary"
            >
              Send
            </Button>
          </div>
        </div>
      </TuvaModal>
    );
  }
}

export default FeedBackModel;
