import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom/client';
import { findDOMNode } from 'react-dom';
import loadScript from 'load-script';
import PropTypes from 'prop-types';
import Loading from './Loading';

// To give react reference to TuvaDataTools
window.React = React;
window.ReactDOM = ReactDOM;
// TODO: replace findDOMNode with useRef
window.ReactDOM.findDOMNode = findDOMNode;

const TOOLS_URL = window.TUVA_DATA_TOOL_URL;

export function isTuvaToolsLoaded(cb) {
  if (!window.TuvaDataTools) {
    loadScript(TOOLS_URL, function () {
      cb(true);
    });
  } else {
    cb(true);
  }
}

const TuvaDataTool = React.forwardRef(function (
  {
    columnIds,
    columnNames,
    rowData,
    metaData,
    plotState,
    isTuva,
    editable,
    onTableDataSave,
    onAddAttrClick,
    conf,
    onToolsLoaded,
  },
  ref,
) {
  const [isToolsLoaded, setIsToolsLoaded] = useState(!!window.TuvaDataTools);
  const onToolsLoad = useCallback(() => {
    setIsToolsLoaded(true);
    if (onToolsLoaded) {
      onToolsLoaded();
    }
  }, [onToolsLoaded]);

  useEffect(() => {
    if (!window.TuvaDataTools) {
      isTuvaToolsLoaded(onToolsLoad);
    } else if (onToolsLoaded) {
      onToolsLoad();
    }
  }, [onToolsLoad, onToolsLoaded]);

  const TuvaDataTools = window.TuvaDataTools;
  if (isToolsLoaded) {
    return (
      <TuvaDataTools
        ref={ref}
        columnIds={columnIds}
        columnNames={columnNames}
        rowData={rowData}
        metaData={metaData}
        isTuva={isTuva}
        editable={editable}
        plotState={plotState}
        onTableDataSave={onTableDataSave}
        onAddAttrClick={onAddAttrClick}
        conf={conf}
      />
    );
  } else {
    return (
      <div className="py-6">
        <Loading show={true} />
      </div>
    );
  }
});

TuvaDataTool.propTypes = {
  columnIds: PropTypes.array,
  columnNames: PropTypes.array,
  rowData: PropTypes.array,
  metaData: PropTypes.object,
  isTuva: PropTypes.bool,
};

TuvaDataTool.defaultProps = {
  columnIds: [],
  columnNames: [],
  rowData: [],
  metaData: undefined,
  isTuva: true,
};

export default TuvaDataTool;
