import React from 'react';
import './index.scss';
function generateSkeleton(count = 1) {
  let arr = [];
  for (let i = 0; i < count; i++) {
    arr.push(
      <ul
        key={i + 'skeleton'}
        className="o-vertical-spacing o-vertical-spacing--l border-bottom border-gray-300"
      >
        <li className="blog-post o-media" style={{ margin: '32px' }}>
          <div className="o-media__figure">
            <span
              className="skeleton-box"
              style={{ width: '276px', height: '276px', display: 'none' }}
            />
          </div>
          <div className="o-media__body">
            <div className="o-vertical-spacing">
              <h3 className="blog-post__headline">
                <span className="skeleton-box" style={{ width: '15%' }} />
              </h3>
              <p>
                <span className="skeleton-box" style={{ width: '100%' }} />
                <span className="skeleton-box" style={{ width: '80%' }} />
              </p>
              <div className="blog-post__meta">
                <span className="skeleton-box" style={{ width: '50%' }} />
              </div>
              <div className="blog-post__meta">
                <span className="skeleton-box" style={{ width: '50%' }} />
              </div>
            </div>
          </div>
        </li>
      </ul>,
    );
  }

  return arr;
}

export function CardBlockSkeleton(props) {
  let count = props.count;
  return generateSkeleton(count);
}

export var SelectSkeleton = ({ height, bgColor }) => {
  return (
    <ul className="o-vertical-spacing o-vertical-spacing--l ml-0">
      <li className="blog-post o-media">
        <div className="o-media__body m-0">
          <div className="o-vertical-spacing">
            <h3 className="blog-post__headline">
              <span
                className={`skeleton-box ${bgColor}`}
                style={{ width: '100%', height: `${height || '45px'}` }}
              />
            </h3>
          </div>
        </div>
      </li>
    </ul>
  );
};

function generateSidebarSkeleton(n) {
  let arr = [];
  for (let i = 0; i < n; i++) {
    arr.push(
      <div key={`skeleton-key-${i}`} className="blog-post__meta">
        <span
          className="skeleton-box"
          style={{ width: '100%', height: '26px' }}
        />
      </div>,
    );
  }

  return arr;
}

export var SidebarSkeleton = props => {
  let arr = [];
  arr.length = 15;
  return (
    <ul className="o-vertical-spacing o-vertical-spacing--l border-bottom border-gray-300 height-full ml-0">
      <li className="blog-post o-media m-3">
        <div className="o-media__body m-0 pt-0">
          <div className="o-vertical-spacing">
            {generateSidebarSkeleton(props.Lines || 15)}
          </div>
        </div>
      </li>
    </ul>
  );
};
