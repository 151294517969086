import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AnchorWithoutLink } from '@tuva-ui/components';
import MetadataModel from '../../MetadataModel/MetadataModel';

const Metadata = ({ datasetId, type, appTypeBaseUrl }) => {
  const [showMetadataModal, setShowMetadataModal] = useState(false);
  return (
    <>
      <AnchorWithoutLink
        className="px-1"
        onClick={() => setShowMetadataModal(true)}
      >
        <i className="ti ti-metadata" title="Show Metadata History"></i>
      </AnchorWithoutLink>
      {showMetadataModal && (
        <MetadataModel
          datasetId={datasetId}
          type={type}
          appTypeBaseUrl={appTypeBaseUrl}
          onClose={() => setShowMetadataModal(false)}
        />
      )}
    </>
  );
};

Metadata.propTypes = {
  datasetId: PropTypes.number,
  type: PropTypes.string,
  appTypeBaseUrl: PropTypes.string,
};

export default Metadata;
