import React from 'react';
import PropTypes from 'prop-types';

const Refresh = ({ appName, url }) => {
  return (
    <a
      className="pl-1"
      title="Refresh"
      href={url}
      onClick={() => {
        window._gaq &&
          window._gaq.push([
            '_trackEvent',
            'Refresh Dataset',
            'Click',
            { appName },
          ]);
      }}
    >
      <i className="ti ti-refresh" title="Refresh Dataset"></i>
    </a>
  );
};

Refresh.propTypes = {
  appName: PropTypes.string,
  url: PropTypes.string,
};

export default Refresh;
