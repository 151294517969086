var isLSAvailable = false;
const STORAGE_KEY = 'stepsAnswers';
const LARGE_TXT = 'isLargeText';
const TUVA_TXT_TYPE = 'textType';
const SHOW_TEXTSIZE_PICKER = 'showTextsizePicker';

try {
  localStorage.setItem(STORAGE_KEY, '{}');
  isLSAvailable = true;
} catch (e) {}

if (isLSAvailable) {
  // Remove storage key and text size picker on page refresh
  window.onbeforeunload = function () {
    localStorage.removeItem(STORAGE_KEY);
    localStorage.removeItem(SHOW_TEXTSIZE_PICKER);
  };
}

const StepLocalStorageUtil = {
  setStepAns: function (id, ans) {
    if (isLSAvailable) {
      try {
        const stepsAnswers = JSON.parse(localStorage.getItem(STORAGE_KEY));
        stepsAnswers[id] = ans;
        localStorage.setItem(STORAGE_KEY, JSON.stringify(stepsAnswers));
      } catch (error) {
        // Let it to fail siliently
      }
    }
  },
  getStepAns: function (id) {
    if (isLSAvailable) {
      try {
        const stepsAnswers = JSON.parse(localStorage.getItem(STORAGE_KEY));
        return stepsAnswers[id];
      } catch (error) {
        // Let it to fail siliently
      }
    }

    return null;
  },
  removeStepAns: function (id) {
    if (isLSAvailable) {
      try {
        const stepsAnswers = JSON.parse(localStorage.getItem(STORAGE_KEY));
        delete stepsAnswers[id];
        localStorage.setItem(STORAGE_KEY, JSON.stringify(stepsAnswers));
      } catch (error) {
        // Let it to fail siliently
      }
    }
  },
  setTuvaTextChoice: function (type) {
    if (isLSAvailable) {
      try {
        localStorage.setItem(TUVA_TXT_TYPE, type);
      } catch (error) {
        // Let it to fail siliently
      }
    }
  },
  getTuvaTextChoice: function () {
    if (isLSAvailable) {
      /* 
      If user has not yet made any text size selection, the textType variable is undefined. 
      Then, load the default text size for Tuva Jr (Large) and Tuva Regular (Standard).

      If the user changes the text size, the textType variable is set to the user's choice.
      Then, load the user's choice.
      */

      try {
        let tuvaTextType = localStorage.getItem(TUVA_TXT_TYPE);
        if (tuvaTextType) {
          return tuvaTextType;
        } else {
          let fallBackSupport = localStorage.getItem(LARGE_TXT);
          if (fallBackSupport === 'true') {
            localStorage.setItem(TUVA_TXT_TYPE, 'large');
            localStorage.removeItem(LARGE_TXT);
          } else if (fallBackSupport === 'false') {
            localStorage.setItem(TUVA_TXT_TYPE, 'standard');
          }
          localStorage.removeItem(LARGE_TXT);
        }
        return tuvaTextType;
      } catch (error) {
        // Let it to fail siliently
      }
    }
    return null;
  },
  removeLargeText: function () {
    if (isLSAvailable) {
      try {
        localStorage.removeItem(LARGE_TXT);
      } catch (error) {
        // Let it to fail siliently
      }
    }
  },
  setShowTextSizePicker: function (isLarge) {
    if (isLSAvailable) {
      try {
        localStorage.setItem(SHOW_TEXTSIZE_PICKER, isLarge);
      } catch (error) {
        // Let it to fail siliently
      }
    }
  },
  getShowTextSizePicker: function () {
    if (isLSAvailable) {
      try {
        const isLarge =
          localStorage.getItem(SHOW_TEXTSIZE_PICKER) === 'true' || false;
        return isLarge;
      } catch (error) {
        // Let it to fail siliently
      }
    }
    return null;
  },
  removeShowTextSizePicker: function () {
    if (isLSAvailable) {
      try {
        localStorage.removeItem(SHOW_TEXTSIZE_PICKER);
      } catch (error) {
        // Let it to fail siliently
      }
    }
  },
};

export default StepLocalStorageUtil;
