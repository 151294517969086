import React from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, TuvaToast } from '@tuva-ui/components/';

const Embed = ({ url }) => {
  let embedContent =
    '<iframe width="100%" height="645" frameborder="0" seamless="seamless" scrolling="no" src="' +
    url +
    '"></iframe>';
  return (
    <>
      <div className="p-4">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <label className="mr-2">iFrame Object</label>
          <CopyToClipboard
            text={embedContent}
            onCopy={() => TuvaToast.success('iFrame copied successfully')}
          >
            <i className="ti ti-clipboard" style={{ cursor: 'pointer' }} />
          </CopyToClipboard>
        </div>
        <textarea
          className="width-full form-control"
          readOnly={true}
          style={{ height: '120px', resize: 'none' }}
          value={embedContent}
          onFocus={e => e.target.select()}
          onClick={e => e.target.select()}
        ></textarea>

        <div className="d-flex justify-content-between align-items-center mt-4 mb-3">
          <label className="mr-2">Embed URL</label>
          <CopyToClipboard
            text={url}
            onCopy={() => TuvaToast.success('URL copied successfully')}
          >
            <i className="ti ti-clipboard" style={{ cursor: 'pointer' }} />
          </CopyToClipboard>
        </div>
        <textarea
          className="width-full form-control height-full"
          readOnly={true}
          style={{ height: '120px', resize: 'none' }}
          value={url}
          onFocus={e => e.target.select()}
          onClick={e => e.target.select()}
        ></textarea>
      </div>

      <div className="d-flex flex-justify-center">
        <Button
          className="btn btn-outline mr-2"
          onClick={e => (window.location = '/embed/preview/?url=' + url)}
        >
          Preview
        </Button>
      </div>
    </>
  );
};

Embed.propTypes = {
  url: PropTypes.string,
};

export default Embed;
