import React, { useEffect, useState } from 'react';
import { StepLocalStorageUtil, TextSizePicker } from '@tuva-ui/components';
import GoogleTranslate, { initTranslator } from './GoogleTranslate.js';

function StepHeaderCollapsibleWrapper({
  setTextSizeClass,
  isTuvaJR,
  showTextSizePicker,
}) {
  const [classType, setClassType] = useState('');

  // initialize google translate dependencies
  useEffect(() => {
    initTranslator();
  }, []);

  useEffect(() => {
    setClassType(
      StepLocalStorageUtil.getTuvaTextChoice()
        ? StepLocalStorageUtil.getTuvaTextChoice()
        : isTuvaJR
        ? 'large'
        : 'standard',
    );
  }, [isTuvaJR]);

  useEffect(() => {
    StepLocalStorageUtil.setTuvaTextChoice(classType);
    let cssClass = '';
    if (classType === 'large') {
      cssClass += ' large-text';
    } else if (classType === 'extralarge') {
      cssClass += ' extra-large-text';
    }
    setTextSizeClass(cssClass);
  }, [classType, setTextSizeClass]);

  if (showTextSizePicker) {
    return (
      <div className="bg-green-1 px-3 py-2 d-flex flex-justify-between flex-items-center">
        <TextSizePicker
          setClassType={setClassType}
          classType={classType}
          setTextSizeClass={setTextSizeClass}
        />
        <GoogleTranslate />
      </div>
    );
  }

  return <></>;
}

export default StepHeaderCollapsibleWrapper;
