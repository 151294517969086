import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let TuvaToast = {
  success: function name(messsage) {
    toast.success(<div className="break-word">{messsage}</div>, {
      icon: ({ theme, type }) => (
        <span
          className="ti ti-success-o d-inline-flex mr-3 h4"
          style={{ color: '#155724' }}
        ></span>
      ),
    });
  },
  error: function name(messsage) {
    toast.error(<div className="break-word">{messsage}</div>, {
      icon: ({ theme, type }) => (
        <span className="ti ti-warning d-inline-flex mr-3 h4 text-red"></span>
      ),
    });
  },
  warning: function name(messsage) {
    toast.warn(<div className="break-word">{messsage}</div>, {
      icon: ({ theme, type }) => (
        <span className="ti ti-warning d-inline-flex mr-3 h4 text-yellow"></span>
      ),
    });
  },
  info: function name(messsage) {
    toast.info(<div className="break-word">{messsage}</div>, {
      icon: ({ theme, type }) => (
        <span
          className="ti ti-info d-inline-flex mr-3 h4"
          style={{ color: '#1192E8' }}
        ></span>
      ),
    });
  },
};

export function TuvaToastContainer() {
  return (
    <ToastContainer
      hideProgressBar={true}
      autoClose={3000}
      position="top-right"
      closeButton={false}
      style={{ zIndex: 10000 }}
    />
  );
}

export default TuvaToast;
